import { onValue, push, ref, remove, set } from "firebase/database";
import React, { useState } from "react";
import { firebaseDatabase, firebaseDatabaseFidesure, firebaseDatabaseSC } from "../../backend/firebaseHandler";
import { chain } from "lodash";
import moment from "moment";
import slugify from "slugify";
import exportFromJSON from "export-from-json";

const ForTesting = () => {


    const handleUpdate = async () => {

        // let array = ["919360508477", "919381514529", "919381517513", "919390488841", "919392000069", "919398489652", "919398574592", "919489953706", "919505184621", "919515400670", "919517960116", "919542145669", "919542381721", "919550394068", "919553574764", "919588611284", "919676764560", "919701960385", "919754412002", "919848772271", "919872078699", "919873675533", "919876511292", "919877397056", "919912992642"]

        // for (const index in array) {
        //     let phoneNumber = array[index]
            
        //     if (phoneNumber) {
        //         await set(ref(firebaseDatabase, `NBFC_LEAD_STATUS/${phoneNumber}/TataCapital/assignedRmName`), "Simranjeet Kaur")
        //         await set(ref(firebaseDatabase, `NBFC_LEAD_STATUS/${phoneNumber}/TataCapital/assignedRmPhoneNumber`), "8285100265")
        //         await set(ref(firebaseDatabase, `NBFC_LEAD_STATUS/${phoneNumber}/TataCapital/assignedRmUid`), "pFiBdD9L54Z1YTrGLJo9BZ3HNGm2")

        //         await set(ref(firebaseDatabase, `VENDOR_FRESH_LEADS/TataCapital/${phoneNumber}/assignedRmName`), "Simranjeet Kaur")
        //         await set(ref(firebaseDatabase, `VENDOR_FRESH_LEADS/TataCapital/${phoneNumber}/assignedRmPhoneNumber`), "8285100265")
        //         await set(ref(firebaseDatabase, `VENDOR_FRESH_LEADS/TataCapital/${phoneNumber}/assignedRmUid`), "pFiBdD9L54Z1YTrGLJo9BZ3HNGm2")
        //     }
            
        // }
        // console.log("done")




        // handleSCSide("919592197800", "ugdHOgc2qybwIdZ2OV0T24XxOSR2", "Sachin Sharma", "ib3qWcwVDbfctClRlIu07lX0BA23", "Meenakshi Sharma", "8882994260")
        // handleVendorSide("919592197800", "ib3qWcwVDbfctClRlIu07lX0BA23", "Meenakshi Sharma", "8882994260")
    }

    const handleSCSide = (phoneNumber, sourceUid, sourceName, targetUid, targetName, targetNumber) => {

        onValue(ref(firebaseDatabase, `PF_PAID_LEADS/${sourceUid}/${phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let item = snap.val()
                item.assignedAdvisorName = targetName
                item.assignedAdvisorPhoneNumber = targetNumber
                item.assignedAdvisorUid = targetUid
                item.reAssigningDate = moment().format("DD-MM-YYYY h:mm a")
                item.reAssignmentDetail = {
                    reason: "Advior In-active",
                    previousAdvisorName: sourceName,
                    previousAdvisorUid: sourceUid
                }

                await set(ref(firebaseDatabase, `PF_PAID_LEADS/${targetUid}/${phoneNumber}`), item)
                await set(ref(firebaseDatabase, `OP_USER_PF_PAID_LEADS/${phoneNumber}`), item)
                await set(ref(firebaseDatabase, `LOAN_APPLICATIONS/${phoneNumber}`), item)
                await remove(ref(firebaseDatabase, `PF_PAID_LEADS/${sourceUid}/${phoneNumber}`))
                console.log("done")
            }
        }, {onlyOnce:true})
    }

    const handleVendorSide = (phoneNumber, targetUid, targetName, targetNumber) => {
        console.log("starting")

        onValue(ref(firebaseDatabase, `NBFC_LEAD_STATUS/${phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    let status = snap.child(key).child("status").val()
                    let vendorTablName = ""
                    switch (status) {
                        case "Pool": {
                            vendorTablName = `VENDOR_FILE_POOL`
                            break;
                        }
                        case "Not Shared": {
                            vendorTablName = `VENDOR_FILE_POOL`
                            break;
                        }
                        case "In-review": {
                            vendorTablName = `VENDOR_LEADS`
                            break;
                        }
                        case "Fresh": {
                            vendorTablName = `VENDOR_FRESH_LEADS`
                            break;
                        }
                        case "Re-login": {
                            vendorTablName = `VENDOR_RE_LOGIN_LEADS`
                            break;
                        }
                        case "Re-look": {
                            vendorTablName = `VENDOR_RE_LOOK_LEADS`
                            break;
                        }
                        case "Re-check": {
                            vendorTablName = `VENDOR_FRESH_LEADS`
                            break;
                        }
                        case "Incomplete File": {
                            vendorTablName = `VENDOR_FRESH_LEADS`
                            break;
                        }
                        case "Follow Up": {
                            vendorTablName = `VENDOR_FRESH_LEADS`
                            break;
                        }
                        case "On-hold": {
                            vendorTablName = `VENDOR_FRESH_LEADS`
                            break;
                        }
                        case "Closed": {
                            vendorTablName = `VENDOR_CLOSED_LEADS`
                            break;
                        }
                        case "Updated": {
                            vendorTablName = `VENDOR_FRESH_LEADS`
                            break;
                        }
                        case "Not Contactable": {
                            vendorTablName = `VENDOR_FRESH_LEADS`
                            break;
                        }
                        case "Not Doable": {
                            vendorTablName = `VENDOR_FRESH_LEADS`
                            break;
                        }
                        case "Logged-in": {
                            vendorTablName = `VENDOR_FRESH_LEADS`
                            break;
                        }
                        case "Approved": {
                            vendorTablName = `VENDOR_APPROVED_LEADS`
                            break;
                        }
                        case "Rejected": {
                            vendorTablName = `VENDOR_REJECTED_LEADS`
                            break;
                        }
                        case "Shared": {
                            vendorTablName = `VENDOR_APPROVED_LEADS`
                            break;
                        }
                        case "Rejected by Student": {
                            vendorTablName = `VENDOR_AUTO_REJECT_LEADS`
                            break;
                        }
                        case "Accepted by Student": {
                            vendorTablName = `VENDOR_APPROVED_LEADS`
                            break;
                        }
                        case "Disbursed": {
                            vendorTablName = `VENDOR_DISBURSED_LEADS`
                            break;
                        }
                        case "SL Received": {
                            vendorTablName = `VENDOR_SL_SENT_LEADS`
                            break;
                        }
                        default: {
                            console.log("Not present for: " + key)
                        }
                    }

                    await set(ref(firebaseDatabase, `${vendorTablName}/${key}/${phoneNumber}/assignedAdvisorName`), targetName)
                    await set(ref(firebaseDatabase, `${vendorTablName}/${key}/${phoneNumber}/assignedAdvisorPhoneNumber`), targetNumber)
                    await set(ref(firebaseDatabase, `${vendorTablName}/${key}/${phoneNumber}/assignedAdvisorUid`), targetUid)

                    console.log("Done with " + key + " at " + status)
                }
            }
        }, {onlyOnce:true})
    }

    

    const handleTransfer = () => {
        onValue(ref(firebaseDatabase, `DISBURSED_LEADS`), (snap) => {
            if (snap.exists()) {
                for (const uid in snap.val()) {
                    for (const number in snap.child(uid).val()) {
                        console.log(snap.child(uid).child(number).child("assignedAdvisorName").val())
                    }
                }
            }
        }, {onlyOnce:true})
    }


    const movingInterested = () => {
        onValue(ref(firebaseDatabase, "INTERESTED_LEADS/EFDNnrNfVfhberstedJo7BDpwRt2/919500606429"), async (snap) => {
            if (snap.exists()) {
                let item = snap.val()
                item.assignedAdvisorName = "Meenakshi Sharma"
                item.assignedAdvisorPhoneNumber = "8882994260"
                item.assignedAdvisorUid = "ib3qWcwVDbfctClRlIu07lX0BA23"
                item.reAssigningDate = moment().format("DD-MM-YYYY h:mm a")
                item.reAssignmentDetail = {
                    reason: "Transferred for documents",
                    previousAdvisorName: "Garvit Sagar",
                    previousAdvisorUid: "EFDNnrNfVfhberstedJo7BDpwRt2"
                }

                await set(ref(firebaseDatabase, "LOAN_APPLICATIONS/919500606429"), item)
                await set(ref(firebaseDatabase, "INTERESTED_LEADS/ib3qWcwVDbfctClRlIu07lX0BA23/919500606429"), item)
                await remove(ref(firebaseDatabase, "INTERESTED_LEADS/EFDNnrNfVfhberstedJo7BDpwRt2/919500606429"))
                console.log("Done")
            } else {
                console.log("Oops")
            }
        }, {onlyOnce:true})
    }

    const scPlanCreation = () => {
        // let temp = []

        // onValue(ref(firebaseDatabaseFidesure, 'SUCCESS_SALES'), (snap) => {
        //     if (snap.exists()) {
        //         for (const key in snap.val()) {
        //             let item = snap.child(key).val()
        //             let tempObj = {
        //                 "Student Name": item.firstName + " " + item.lastName,
        //                 "Phone Number": item.phoneNumber,
        //                 "Email-ID": item.emailId,
        //                 "Alternate Number": item.alternateNumber,
        //                 "DOB": item.dob,
        //                 "Gender": item.gender,
        //                 "University": item.university,
        //                 "Order ID": item.orderId,
        //                 "Plan Name": item.selectedPlan.name,
        //                 "Start Date": item.startDate,
        //                 "End Date": item.endDate

        //             }
        //             temp.push(tempObj)
                    
        //         }
        //         const fileName = 'Fidesure Purchase wdawdaw'
        //         const exportType = exportFromJSON.types.csv
        //         exportFromJSON({ data:temp, fileName, exportType })

        //     }
        // })
    }

    return (
        <div style={{padding:50}}>
            <h1 onClick={()=>{scPlanCreation()}}>Update</h1>
        </div>
    )
}

export default ForTesting


