import React, { useContext, useEffect, useState } from "react";
import Styles from "../AdminCompleteDetail/AdminCompleteDetail.module.css"
import { useLocation } from "react-router-dom";
import UserContext from "../../context/UserData.context";
import { Alert, Button, MenuItem, Select, Snackbar, Tab, Tabs } from "@mui/material";
import { FaExternalLinkSquareAlt } from "react-icons/fa"
import relationMap from "../../workload/CoSignerRelationMap";
import InputContainer from "../InputContainer/InputContainer.component";
import getMailList from "../../workload/MailList";
import getNbfcList from "../../workload/NbfcList";

const ScViewFile = () => {

    const location = useLocation()
    const [application, setApplication] = useState(location.state.item)
    const [caseDetail, setCaseDetail] = useState(application.caseDetail?application.caseDetail:{})
    const [value, setValue] = React.useState(0);
    const [examsWritten, setExamsWritten] = useState([])
    const [mailList, setMailList] = useState(getMailList())

    useEffect(() => {
        if (application.examsWritten) {
            setExamsWritten(application.examsWritten)
        }

        if (application.mailList) {
            setMailList(application.mailList)
        }else {
            setMailList(getMailList());
        }
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div className={Styles.adminCompleteDetail}>
            <div className={Styles.headerContainer}>
                <h1 style={{ color: "#352F6C" }}>Lead Detail</h1>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, marginBottom: 40 }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Basic Details" {...a11yProps(0)} />
                    <Tab label="Case Details" {...a11yProps(1)} />
                    <Tab label="Applicant Docs" {...a11yProps(2)} />
                    <Tab label="Co-Applicant Docs" {...a11yProps(3)} />
                    <Tab label="Non Financial Docs" {...a11yProps(4)} />
                </Tabs>
            </div>

            <div className={Styles.formListContainer}>
                {
                    value === 0
                    &&
                    <div className={Styles.formContainer}>
                        <InputContainer value={application.name} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={"91XXXXXXXXX"} readOnly className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={application.emailId} readOnly className={Styles.inputField} placeholder="Email Id" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Date of Birth</p>
                            <input type="date" value={application.dob} readOnly className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Level of Education</p>
                            <Select style={{ width: "100%" }} value={application.levelOfStudy} readOnly disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="UG">UG</MenuItem>
                                <MenuItem value="PG">PG</MenuItem>
                                <MenuItem value="Diploma">Diploma</MenuItem>
                                <MenuItem value="Double Master">Double Master</MenuItem>
                                <MenuItem value="PHD">PHD</MenuItem>
                            </Select>
                        </div>

                        <div style={{ width: "100%", gridColumn: "1/2"  }}>
                            <p className={Styles.label}>Currently in India?</p>
                            <Select style={{ width: "100%" }} value={application.currentlyInIndia} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </Select>
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Abroad Phone Number</p>
                            <input value={application.abroadPhoneNumber} readOnly className={Styles.inputField} placeholder="Abroad Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%", gridColumn: "1/2" }}>
                            <p className={Styles.label}>Country of Study</p>
                            <input value={application.desiredCountry} readOnly className={Styles.inputField} placeholder="Desired Country" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>University of Choice</p>
                            <input value={application.desiredUniversity} readOnly className={Styles.inputField} placeholder="Desired University" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Entrance Exams written</p>
                            <Select multiple style={{ width: "100%" }} value={examsWritten} readOnly disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="GRE">GRE</MenuItem>
                                <MenuItem value="TOFEL">TOFEL</MenuItem>
                                <MenuItem value="IELTS">IELTS</MenuItem>
                                <MenuItem value="Duolingo">Duolingo</MenuItem>
                                <MenuItem value="PTE">PTE</MenuItem>
                                <MenuItem value="Other Exam">Other Exam</MenuItem>
                            </Select>
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Loan amount required</p>
                            <input value={application.requiredLoanAmount} readOnly className={Styles.inputField} placeholder="Loan amount required" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        {/* <div className={Styles.yesNoContainer}>
                            <p>Have you applied to any Bank?</p>
                            <p style={{ color: application.hasPreAppliedToBank === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} >Yes</p>
                            <p>|</p>
                            <p style={{ color: application.hasPreAppliedToBank === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} >No</p>
                        </div>

                        <input value={application.preAppliedBank} readOnly className={Styles.inputField} placeholder="Bank Name" InputProps={{ disableUnderline: true }} variant="standard" /> */}

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Work experience?</p>
                            <Select style={{ width: "100%" }} value={application.hasWorkExperience} readOnly disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="Yes">Currently Working</MenuItem>
                                <MenuItem value="Past">Used to work</MenuItem>
                                <MenuItem value="No">No work experience</MenuItem>
                            </Select>
                        </div>

                        <div className={Styles.yesNoContainer}>
                            <p>Have your own house?</p>
                            <p style={{ color: application.hasOwnHouse === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} >Yes</p>
                            <p>|</p>
                            <p style={{ color: application.hasOwnHouse === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} >No</p>
                        </div>

                        <div style={{ width: "100%", gridColumn: "1/2" }}>
                            <p className={Styles.label}>Who will be your co-applicant?</p>
                            <Select style={{ width: "100%" }} value={application.coApplicant} readOnly disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                {
                                    Object.keys(relationMap).map((item, index) => {
                                        return (
                                            <MenuItem value={item}>{item}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </div>

                        {
                            application.coApplicant === "Other"
                            &&
                            <div style={{ width: "100%" }}>
                                <p className={Styles.label}>Co-applicant relation</p>
                                <input value={application.otherCoApplicant} className={Styles.inputField} placeholder="Co-applicant relation" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        }

                        {
                            application.coApplicant !== ""
                            &&
                            <>
                                <div style={{ width: "100%" }}>
                                    <p className={Styles.label}>Co-applicant's Profession?</p>
                                    <Select style={{ width: "100%" }} value={application.coApplicantProfession} readOnly disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                        <MenuItem value="Salaried">Salaried</MenuItem>
                                        <MenuItem value="Self Employed - Professional">Self Employed - Professional</MenuItem>
                                        <MenuItem value="Self Employed - Non Professional">Self Employed - Non Professional</MenuItem>
                                        <MenuItem value="Farmer">Farmer</MenuItem>
                                        <MenuItem value="Pensioner">Pensioner</MenuItem>
                                        <MenuItem value="Avanse Global">Avanse Global</MenuItem>
                                    </Select>
                                </div>
                                <div style={{ width: "100%" }}>
                                    <p className={Styles.label}>Co-applicant's Age?</p>
                                    <input value={application.coApplicantAge} readOnly className={Styles.inputField} placeholder="Co-applicant's Age" InputProps={{ disableUnderline: true }} variant="standard" />
                                </div>
                                <div style={{ width: "100%" }}>
                                    <p className={Styles.label}>Co-applicant in India?</p>
                                    <Select style={{ width: "100%" }} value={application.coApplicantInIndia} readOnly disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </div>
                            </>
                        }                   

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Is this a BT case?</p>
                            <Select style={{ width: "100%" }} value={application.isBTCase} readOnly disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </Select>
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Re-look in?</p>
                            <Select multiple style={{ width: "100%" }} value={application.reLookIn?application.reLookIn:[]} readOnly disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                {
                                    getNbfcList().map((item) => {return (
                                        <MenuItem value={item.uid}>{item.name}</MenuItem>
                                    )})
                                }
                            </Select>
                        </div>

                        <div style={{gridColumn:"1/4"}}>
                            {
                                relationMap[application.coApplicant].map((item, index) => {
                                    return (
                                        <div className={Styles.nonFinListContainer}>
                                            <div style={{ width: "100%" }}>
                                                <p className={Styles.label} style={{ color: "rgba(0,0,0,0)" }}>Number</p>
                                                <input value={"Non Financial Applicant " + (index + 1)} readOnly className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                                            </div>
                                            <div style={{ width: "100%" }}>
                                                <p className={Styles.label}>Relation</p>
                                                <input value={item} readOnly className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                                            </div>
                                            {
                                                item === "Father" && (application.coApplicant !== "Father" && application.coApplicant !== "Mother")
                                                &&
                                                <div style={{ width: "100%" }}>
                                                    <p className={Styles.label}>Why isn't your {item} a financial Co-applicant?</p>
                                                    <Select style={{ width: "100%" }} value={application.whyFatherNotFin} readOnly disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                                        <MenuItem value="Poor Cibil">Poor Cibil</MenuItem>
                                                        <MenuItem value="No Income Proof or Low banking">No Income Proof or Low banking</MenuItem>
                                                        <MenuItem value="Divorced">Divorced</MenuItem>
                                                        <MenuItem value="Deceased">Deceased</MenuItem>
                                                    </Select>
                                                </div>
                                            }
                                            {
                                                item === "Mother" && (application.coApplicant !== "Father" && application.coApplicant !== "Mother")
                                                &&
                                                <div style={{ width: "100%" }}>
                                                    <p className={Styles.label}>Why isn't your {item} a financial Co-applicant?</p>
                                                    <Select style={{ width: "100%" }} value={application.whyMotherNotFin} readOnly disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                                        <MenuItem value="Poor Cibil">Poor Cibil</MenuItem>
                                                        <MenuItem value="No Income Proof or Low banking">No Income Proof or Low banking</MenuItem>
                                                        <MenuItem value="Divorced">Divorced</MenuItem>
                                                        <MenuItem value="Deceased">Deceased</MenuItem>
                                                    </Select>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>

                        {
                            application.isSpecialCase
                            &&
                            <div style={{ width: "100%", gridColumn:"1/4" }}>
                                <p className={Styles.label}>This has been marked as a Special Case</p>
                                <input value={application.specialCaseDescription} readOnly className={Styles.inputFieldSpecial} InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        }
                    </div>
                }

                {
                    (value === 1 && application.caseDetail)
                    &&
                    <>
                        <p className={Styles.cdTabTitle}>Student Detail</p>
                        <div className={Styles.formContainer}>
                            <InputContainer value={application.name} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.phoneNumber} readOnly className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.emailId} readOnly className={Styles.inputField} placeholder="Email ID" InputProps={{ disableUnderline: true }} variant="standard" />
                            
                            <InputContainer value={caseDetail.fatherName} readOnly className={Styles.inputField} placeholder="Father's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.motherName} readOnly className={Styles.inputField} placeholder="Mother's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.requiredLoanAmount} readOnly className={Styles.inputField} placeholder="Loan Amount Required" InputProps={{ disableUnderline: true }} variant="standard" />
                            
                            <div style={{ width: "100%" }}>
                                <p className={Styles.label}>Total cost of education</p>
                                <input value={caseDetail.costOfEducation} readOnly className={Styles.inputField} placeholder="Total cost of education" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                            <div style={{ width: "100%" }}>
                                <p className={Styles.label}>Marital Status</p>
                                <Select style={{ width: "100%" }} value={caseDetail.maritalStatus} readOnly disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                    <MenuItem value="Single">Single</MenuItem>
                                    <MenuItem value="Married">Married</MenuItem>
                                    <MenuItem value="Divorced">Divorced</MenuItem>
                                </Select>
                            </div>
                            <div style={{ width: "100%" }}>
                                <p className={Styles.label}>Pan Number</p>
                                <input value={caseDetail.panNumber} readOnly className={Styles.inputField} placeholder="Pan Number" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>

                            <div style={{gridColumn:"1/4"}}>
                                <InputContainer value={caseDetail.currentAddress} readOnly style={{gridColumn:"1/4"}} className={Styles.inputField} placeholder="Current Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                            <div style={{gridColumn:"1/4"}}>
                                <InputContainer value={caseDetail.permanentAddress} readOnly style={{gridColumn:"1/4"}} className={Styles.inputField} placeholder="Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        </div>

                        <p className={Styles.cdTabTitle}>Academic Detail</p>
                        <div className={Styles.formContainer}>
                            <InputContainer value={caseDetail.tenth} readOnly className={Styles.inputField} placeholder="10th" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.twelfth} readOnly className={Styles.inputField} placeholder="12th" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.graduation} readOnly className={Styles.inputField} placeholder="Graduation" InputProps={{ disableUnderline: true }} variant="standard" />
                            
                            {
                                application.examsWritten
                                &&
                                application.examsWritten.map((item, index) => {return (
                                    <InputContainer value={caseDetail[item]} readOnly className={Styles.inputField} placeholder={item} InputProps={{ disableUnderline: true }} variant="standard" />
                                )})
                            }

                        </div>

                        <p className={Styles.cdTabTitle}>Reference Detail</p>
                        <div className={Styles.formContainer}>
                            <InputContainer value={caseDetail.referenceName} onChange={(event) => { setCaseDetail({ ...caseDetail, referenceName: event.target.value })}} style={{gridColumn:"1/2"}} className={Styles.inputField} placeholder="Student Reference Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.referenceNumber} onChange={(event) => { setCaseDetail({ ...caseDetail, referenceNumber: event.target.value }) }} className={Styles.inputField} placeholder="Student Reference Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.referenceOccupation} onChange={(event) => { setCaseDetail({ ...caseDetail, referenceOccupation: event.target.value }) }} className={Styles.inputField} placeholder="Student Reference Occupation" InputProps={{ disableUnderline: true }} variant="standard" />
                            <div style={{gridColumn:"1/4"}}>
                                <InputContainer value={caseDetail.referenceAddress} onChange={(event) => { setCaseDetail({ ...caseDetail, referenceAddress: event.target.value }) }} style={{gridColumn:"1/4"}} className={Styles.inputField} placeholder="Student Reference Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        </div>

                        <p className={Styles.cdTabTitle}>Co-Applicant Detail</p>
                        <div className={Styles.formContainer}>
                            <InputContainer value={caseDetail.coAppName} readOnly className={Styles.inputField} placeholder="Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppPhoneNumber} readOnly className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppEmailId} readOnly className={Styles.inputField} placeholder="Email Id" InputProps={{ disableUnderline: true }} variant="standard" />

                            <InputContainer value={caseDetail.coAppFatherName} readOnly className={Styles.inputField} placeholder="Father's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppMotherName} readOnly className={Styles.inputField} placeholder="Mother's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppQualification} readOnly className={Styles.inputField} placeholder="Qualification" InputProps={{ disableUnderline: true }} variant="standard" />

                            <InputContainer value={caseDetail.coAppNoOfDependents} readOnly className={Styles.inputField} placeholder="Number of Dependents" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppYearsInCurrentHouse} readOnly className={Styles.inputField} placeholder="No. of years in current house" InputProps={{ disableUnderline: true }} variant="standard" />
                            
                            <div style={{gridColumn:"1/4"}}>
                                <InputContainer value={caseDetail.coAppCurrentAddress} readOnly style={{gridColumn:"1/4"}} className={Styles.inputField} placeholder="Current Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                            <div style={{gridColumn:"1/4"}}>
                                <InputContainer value={caseDetail.coAppPermanentAddress} readOnly style={{gridColumn:"1/4"}} className={Styles.inputField} placeholder="Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>

                            <InputContainer value={caseDetail.coAppYearsInCurrentOffice} readOnly className={Styles.inputField} placeholder="No. of years in current office" InputProps={{ disableUnderline: true }} variant="standard" />
                            <div style={{gridColumn:"2/4"}}>
                                <InputContainer value={caseDetail.coAppOfficeAddress} readOnly style={{gridColumn:"1/4"}} className={Styles.inputField} placeholder="Office Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>

                            <InputContainer value={caseDetail.coAppRefName} readOnly className={Styles.inputField} placeholder="Reference's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppRefPhoneNumber} readOnly className={Styles.inputField} placeholder="Reference's Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                            <InputContainer value={caseDetail.coAppRefOccupation} readOnly className={Styles.inputField} placeholder="Reference's Occupation" InputProps={{ disableUnderline: true }} variant="standard" />
                            <div style={{gridColumn:"1/4"}}>
                                <InputContainer value={caseDetail.coAppRefPermanentAddress} readOnly style={{gridColumn:"1/4"}} className={Styles.inputField} placeholder="Reference's Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>

                        </div>

                        <p className={Styles.cdTabTitle}>Non Financial Applicant Detail</p>
                        <div className={Styles.subFormContainer}>
                            {
                                application.nonFinancialCoApplicants.map((item, index) => {return(
                                    <>
                                        {
                                            item === "Father" && (application.whyFatherNotFin === "Divorced"||application.whyFatherNotFin === "Deceased")
                                            ?
                                            null
                                            :
                                            item === "Mother" && (application.whyMotherNotFin === "Divorced"||application.whyMotherNotFin === "Deceased")
                                            ?
                                            null
                                            :
                                            <>
                                                <p className={Styles.nfRelationLabel}>{item}</p>
                                                <div className={Styles.formContainer} style={{marginBottom:20}}>
                                                    <InputContainer value={caseDetail.nfInformation[index].name} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                                                    <InputContainer value={caseDetail.nfInformation[index].phoneNumber} readOnly className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                                                    <InputContainer value={caseDetail.nfInformation[index].emailId} readOnly className={Styles.inputField} placeholder="Email ID" InputProps={{ disableUnderline: true }} variant="standard" />
                                                    
                                                    <InputContainer value={caseDetail.nfInformation[index].fatherName} readOnly className={Styles.inputField} placeholder="Father's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                                                    <InputContainer value={caseDetail.nfInformation[index].motherName} readOnly className={Styles.inputField} placeholder="Mother's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                                                    <InputContainer value={caseDetail.nfInformation[index].qualification} readOnly className={Styles.inputField} placeholder="Occupation" InputProps={{ disableUnderline: true }} variant="standard" />

                                                    <InputContainer value={caseDetail.nfInformation[index].noOfDependents} readOnly className={Styles.inputField} placeholder="Number of Dependents" InputProps={{ disableUnderline: true }} variant="standard" />
                                                    <InputContainer value={caseDetail.nfInformation[index].noOfYearInCurrentHouse} readOnly className={Styles.inputField} placeholder="No. of years in current house" InputProps={{ disableUnderline: true }} variant="standard" />
                                                    
                                                    <div style={{gridColumn:"1/4"}}>
                                                        <InputContainer value={caseDetail.nfInformation[index].currentAddress} readOnly style={{gridColumn:"1/4"}} className={Styles.inputField} placeholder="Current Address" InputProps={{ disableUnderline: true }} variant="standard" />
                                                    </div>
                                                    <div style={{gridColumn:"1/4"}}>
                                                        <InputContainer value={caseDetail.nfInformation[index].permanentAddress} readOnly style={{gridColumn:"1/4"}} className={Styles.inputField} placeholder="Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        
                                    </>
                                )})
                            }

                            {
                                application.additionalNonFinancialRelation
                                &&
                                application.additionalNonFinancialRelation.map((item, index) => {
                                    return (
                                        <>
                                            <p className={Styles.nfRelationLabel}>{item}</p>
                                            <div className={Styles.formContainer} style={{marginBottom:20}}>
                                                    <InputContainer value={caseDetail.additionalNfInformation[index].name} readOnly className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                                                    <InputContainer value={caseDetail.additionalNfInformation[index].phoneNumber} readOnly className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                                                    <InputContainer value={caseDetail.additionalNfInformation[index].emailId} readOnly className={Styles.inputField} placeholder="Email ID" InputProps={{ disableUnderline: true }} variant="standard" />
                                                    
                                                    <InputContainer value={caseDetail.additionalNfInformation[index].fatherName} readOnly className={Styles.inputField} placeholder="Father's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                                                    <InputContainer value={caseDetail.additionalNfInformation[index].motherName} readOnly className={Styles.inputField} placeholder="Mother's Name" InputProps={{ disableUnderline: true }} variant="standard" />
                                                    <InputContainer value={caseDetail.additionalNfInformation[index].qualification} readOnly className={Styles.inputField} placeholder="Occupation" InputProps={{ disableUnderline: true }} variant="standard" />

                                                    <InputContainer value={caseDetail.additionalNfInformation[index].noOfDependents} readOnly className={Styles.inputField} placeholder="Number of Dependents" InputProps={{ disableUnderline: true }} variant="standard" />
                                                    <InputContainer value={caseDetail.additionalNfInformation[index].noOfYearInCurrentHouse} readOnly className={Styles.inputField} placeholder="No. of years in current house" InputProps={{ disableUnderline: true }} variant="standard" />
                                                    
                                                    <div style={{gridColumn:"1/4"}}>
                                                        <InputContainer value={caseDetail.additionalNfInformation[index].currentAddress} readOnly style={{gridColumn:"1/4"}} className={Styles.inputField} placeholder="Current Address" InputProps={{ disableUnderline: true }} variant="standard" />
                                                    </div>
                                                    <div style={{gridColumn:"1/4"}}>
                                                        <InputContainer value={caseDetail.additionalNfInformation[index].permanentAddress} readOnly style={{gridColumn:"1/4"}} className={Styles.inputField} placeholder="Permanent Address" InputProps={{ disableUnderline: true }} variant="standard" />
                                                    </div>
                                                </div>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </>
                }

                {
                    value === 2
                    &&
                    <>
                        <div className={Styles.documentListContainer}>
                            {
                                !application.applicantDocs && !application.examDocs && !application.currentlyInIndia
                                ?
                                <p className={Styles.informationMessage}>Complete information not available to generate LOD</p>
                                :
                                application.applicantDocs
                                ?
                                application.applicantDocs.map((item, index) => {
                                    return (
                                        <div className={Styles.documentItem}>
                                            <p style={{color:item.url===""?null:"#12b004"}} className={Styles.documentName}>{item.name}</p>
                                            <FaExternalLinkSquareAlt onClick={()=>{window.open(item.url, "_blank")}} color={item.url===""?"":"#12b004"} style={{cursor:"pointer"}} />
                                        </div>
                                    )
                                })
                                :
                                null
                            }

                        </div>
                        <div style={{ marginTop: 20 }} className={Styles.documentListContainer}>
                            {
                                application.abroadDocs
                                &&
                                application.abroadDocs.map((item, index) => {
                                    return (
                                        <div className={Styles.documentItem}>
                                            <p style={{color:item.url===""?null:"#12b004"}} className={Styles.documentName}>{item.name}</p>
                                            <FaExternalLinkSquareAlt onClick={()=>{window.open(item.url, "_blank")}} color={item.url===""?"":"#12b004"} style={{cursor:"pointer"}} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div style={{ marginTop: 20 }} className={Styles.documentListContainer}>
                            {
                                application.examDocs
                                &&
                                application.examDocs.map((item, index) => {
                                    return (
                                        <div className={Styles.documentItem}>
                                            <p style={{color:item.url===""?null:"#12b004"}} className={Styles.documentName}>{item.name}</p>
                                            <FaExternalLinkSquareAlt onClick={()=>{window.open(item.url, "_blank")}} color={item.url===""?"":"#12b004"} style={{cursor:"pointer"}} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
                }

                {
                    value === 3
                    &&
                    <div className={Styles.documentListContainer}>
                        {
                            application.coApplicantDocs
                                ?
                                application.coApplicantDocs.map((item, index) => {
                                    return (
                                        <div className={Styles.documentItem}>
                                            <p style={{color:item.url===""?null:"#12b004"}} className={Styles.documentName}>{item.name}</p>
                                            <FaExternalLinkSquareAlt  onClick={()=>{window.open(item.url, "_blank")}} color={item.url===""?"":"#12b004"} style={{cursor:"pointer"}} />
                                        </div>
                                    )
                                })
                                :
                                <p className={Styles.informationMessage}>Complete information not available to generate LOD</p>
                        }
                        {
                            application.abroadCoAppDocs
                            &&
                            application.abroadCoAppDocs.map((item, index) => {
                                return (
                                    <div className={Styles.documentItem}>
                                        <p style={{color:item.url===""?null:"#12b004"}} className={Styles.documentName}>{item.name}</p>
                                        <FaExternalLinkSquareAlt  onClick={()=>{window.open(item.url, "_blank")}} color={item.url===""?"":"#12b004"} style={{cursor:"pointer"}} />
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                {
                    value === 4
                    &&
                    <div>
                        {
                            application.nonFinancialCoApplicants
                                ?
                                <>
                                    {
                                        application.nonFinancialCoApplicants.map((item, index) => {
                                            return (
                                                <div>
                                                    <p className={Styles.nonFinRelationLabel}>{item}</p>

                                                    <div className={Styles.documentListContainer}>
                                                        {
                                                            application.nonFinancialDocs[index].map((item2, index2) => {
                                                                return (
                                                                    <div className={Styles.documentItem}>
                                                                        <p style={{color:item2.url===""?null:"#12b004"}} className={Styles.documentName}>{item2.name}</p>
                                                                        <FaExternalLinkSquareAlt  onClick={()=>{window.open(item2.url, "_blank")}} color={item2.url===""?"":"#12b004"} style={{cursor:"pointer"}} />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    <div>
                                        <p className={Styles.nonFinRelationClickableLabel}>Additional Non-financial Applicant</p>
                                        {
                                            application.additionalNonFinancialRelation
                                            &&
                                            application.additionalNonFinancialRelation.map((item, index) => {
                                                return (
                                                    <div>
                                                        <p className={Styles.nonFinRelationLabel}>{item}</p>

                                                        <div className={Styles.documentListContainer}>
                                                            {
                                                                application.additionalNonFinancialDocs[index].map((item2, index2) => {
                                                                    return (
                                                                        <div className={Styles.documentItem}>
                                                                            <p style={{ color: item2.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item2.name}</p>
                                                                            <FaExternalLinkSquareAlt onClick={() => { window.open(item2.url, "_blank") }} color={item2.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div>
                                    <p className={Styles.nonFinRelationLabel}>Location Guarantor Documents</p>
                                    <div className={Styles.documentListContainer}>
                                        {
                                            application.locationGuarantorDocs
                                            ?
                                            application.locationGuarantorDocs.map((item, index) => {
                                                return (
                                                    <div className={Styles.documentItem}>
                                                        <p style={{color:item.url===""?null:"#12b004"}} className={Styles.documentName}>{item.name}</p>
                                                        <FaExternalLinkSquareAlt  onClick={()=>{window.open(item.url, "_blank")}} color={item.url===""?"":"#12b004"} style={{cursor:"pointer"}} />
                                                    </div>
                                                )
                                            })
                                            :
                                            <p>None</p>
                                        }
                                    </div>

                                    
                                </div>

                                <div>
                                    <p className={Styles.nonFinRelationLabel}>Consent Mail</p>
                                    <div className={Styles.documentListContainer}>
                                        {
                                            mailList.map((item, index) => {
                                                return (
                                                    <div className={Styles.documentItem}>
                                                        <p style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                                        <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div>
                                    <p className={Styles.nonFinRelationLabel}>Additional Documents</p>
                                    <div className={Styles.documentListContainer}>
                                        {
                                            application.additionalDocuments
                                            ?
                                            application.additionalDocuments.map((item, index) => {
                                                return (
                                                    <div className={Styles.documentItem}>
                                                        <p style={{color:item.url===""?null:"#12b004"}} className={Styles.documentName}>{item.name}</p>
                                                        <FaExternalLinkSquareAlt  onClick={()=>{window.open(item.url, "_blank")}} color={item.url===""?"":"#12b004"} style={{cursor:"pointer"}} />
                                                    </div>
                                                )
                                            })
                                            :
                                            <p>None</p>
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            <p className={Styles.informationMessage}>Complete information not available to generate LOD</p>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default ScViewFile