import React, { useContext, useTransition, lazy, useEffect, Suspense } from "react";
import { SlGraph } from "react-icons/sl";

import Styles from "./HomePage.module.css"
import { useLocation, useNavigate } from "react-router-dom";
import { AiFillHome, AiOutlinePoweroff } from "react-icons/ai";
import { HiUserAdd } from 'react-icons/hi';
import UserContext from "../../context/UserData.context";
import ManageUsers from "../../components/HomePageTabs/ManageUsers/ManageUsers.component";
import { motion } from 'framer-motion';
import { Button, CircularProgress, Divider } from "@mui/material";
import { firebaseAuth, firebaseDatabase } from "../../backend/firebaseHandler";
import { ref, set } from "firebase/database";
import LatestVersion from "../../workload/LatestVersion";
import CrossSale from "../../components/HomePageTabs/CrossSaleLeads/CrossSale.component";
import CrossSaleLeadDetails from "../../components/CrossSaleLeadDetails/CrossSaleLeadDetails.component";
import { FaBullhorn, FaPhone } from "react-icons/fa";
import CrossSaleOperationsLeads from "../../components/HomePageTabs/CrossSaleOperationsLeads/CrossSaleOperationsLeads.component";
import AddSingleLead from "../../components/HomePageTabs/AddSingleLead/AddSingleLead.component";
import VendorWiseLeads from "../../components/HomePageTabs/VendorWiseLeads/VendorWiseLeads.component";
import HiAllLeadStatus from "../../components/HomePageTabs/HiAllLeadStatus/HiAllLeadStatus.component";
import VendorReLookLeads from "../../components/HomePageTabs/VendorReLookLeads/VendorReLookLeads.component";
import CredilaLeads from "../../components/HomePageTabs/CredilaLeads/CredilaLeads.component";
import AdvisorDashboard from "../../components/Dashboards/AdvisorDashboards/AdvisorDashboard.component";
import HiAdminCompleteLeadDetail from "../../components/HiAdminCompleteLeadDetail/HiAdminCompleteLeadDetail.component";
import sendWhatsappNotification from "../../workload/whatsappNotification";
import BTandND from "../../components/HomePageTabs/BTandND/BTandND.component";
import AdminDasbhoard from "../../components/AdminDashboard/AdminDashboard.component";
import AdvisorIncognitoLeads from "../../components/HomePageTabs/AdvisorIncognitoLeads/AdvisorIncognitoLeads.component";
import AdvisorLeadDetailIncognito from "../../components/AdvisorLeadDetailIncognito/AdvisorLeadDetailIncognito.component";
import AdvisorCaseDetailIncognito from "../../components/AdvisorCaseDetailIncognito/AdvisorCaseDetailIncognito.component";
import AdvisorCompleteDetailIncognito from "../../components/AdvisorCompleteDetailIncognito/AdvisorCompleteDetailIncognito.component";
import FutureIntake from "../../components/HomePageTabs/FutureIntake/FutureIntake.component";
import FutureIntakeLeadDetail from "../../components/FutureIntakeLeadDetail/FutureIntakeLeadDetail.component";
import VendorClosedLeads from "../../components/HomePageTabs/VendorClosedLeads/VendorClosedLeads.component";
import VendorViewFile from "../../components/VendorViewFile/VendorViewFile.component";
import VendorOnHoldLeads from "../../components/HomePageTabs/VendorOnHoldLeads/VendorOnHoldLeads.component";
import RegionalManagerLeadStatus from "../../components/HomePageTabs/RegionalManagerLeadStatus/RegionalManagerLeadStatus.component";
import OperationsIncognitoLeads from "../../components/HomePageTabs/OperationsIncognitoLeads/OperationsIncognitoLeads.component";
import OperationsCompleteDetailIncognito from "../../components/OperationsCompleteDetailIncognito/OperationsCompleteDetailIncognito.component";
import { updateEmail } from "firebase/auth";
import ScViewFile from "../../components/ScViewFile/ScViewFile.component";
import ResourcesTab from "../../components/HomePageTabs/ResourcesTab/ResourcesTab.component";
import FeedbackCalls from "../../components/HomePageTabs/FeedbackCalls/FeedbackCalls.component";
import UploadDocs from "../../components/HomePageTabs/UploadDocs/UploadDocs.component";
import OperationsDocumentUploadPage from "../../components/OperationsDocumentUploadPage/OperationsDocumentUploadPage.component";
const AddUser = lazy(_ => import("../../components/AddUser/AddUser.component"));
const EditProfile = lazy(_ => import("../../components/EditProfile/EditProfile.component"));
const DistributeLeads = lazy(_ => import("../../components/HomePageTabs/DistributeLeads/DistributeLeads.component"));
const AdvisorLeads = lazy(_ => import("../../components/HomePageTabs/AdvisorLeads/AdvisorLeads.component"));
const whiteLogo = lazy(_ => import("../../assets/white_logo_sc.svg"))
const AdvisorLeadDetail = lazy(_ => import("../../components/AdvisorLeadDetail/AdvisorLeadDetail.component"));
const AdvisorCaseDetail = lazy(_ => import("../../components/AdvisorCaseDetail/AdvisorCaseDetail.component"));
const AdvisorCompleteDetail = lazy(_ => import("../../components/AdvisorCompleteDetail/AdvisorCompleteDetail.component"));
const OperationsLeads = lazy(_ => import("../../components/HomePageTabs/OperationsLeads/OperationsLeads.component"));
const OperationsCompleteDetail = lazy(_ => import("../../components/OperationsCompleteDetail/OperationsCompleteDetail.component"));
const AdvisorNbfcDetail = lazy(_ => import("../../components/AdvisorNbfcDetail/AdvisorNbfcDetail.component"));
const OperationNbfcDetail = lazy(_ => import("../../components/OperationNbfcDetail/OperationNbfcDetail.component"));
const VendorLeads = lazy(_ => import("../../components/HomePageTabs/VendorLeads/VendorLeads.component"));
const VendorLoginType = lazy(_ => import("../../components/VendorLoginType/VendorLoginType.component"));
const VendorFreshLeads = lazy(_ => import("../../components/HomePageTabs/VendorFreshLeads/VendorFreshLeads.component"));
const VendorReLoginLeads = lazy(_ => import("../../components/HomePageTabs/VendorReLoginLeads/VendorReLoginLeads.component"));
const VendorCompleteDetail = lazy(_ => import("../../components/VendorCompleteDetail/VendorCompleteDetail.component"));
const VendorUpdatePfLeads = lazy(_ => import("../../components/HomePageTabs/VendorUpdatePfLeads/VendorUpdatePfLeads.component"));
const VendorAddPfRoi = lazy(_ => import("../../components/VendorAddPfRoi/VendorAddPfRoi.component"))
const VendorPfPaidLeads = lazy(_ => import("../../components/HomePageTabs/VendorPfPaidLeads/VendorPfPaidLeads.component"));
const VendorSlSentLeads = lazy(_ => import("../../components/HomePageTabs/VendorSlSentLeads/VendorSlSentLeads.component"));
const VendorDisbursementLeads = lazy(_ => import("../../components/HomePageTabs/VendorDisbursementLeads/VendorDisbursementLeads.component"));
const AdminLeadStatus = lazy(_ => import("../../components/HomePageTabs/AdminLeadStatus/AdminLeadStatus.component"));
const AdminCompleteDetail = lazy(_ => import("../../components/AdminCompleteDetail/AdminCompleteDetail.component"));
const CallStats = lazy(_ => import("../CallStats/CallStats.component"));
const ManagerLeads = lazy(_ => import("../../components/HomePageTabs/ManagerLeads/ManagerLeads.component"));
const ManagerNbfcDetail = lazy(_ => import("../../components/ManagerNbfcDetail/ManagerNbfcDetail.component"));
const InboundLeadsStatus = lazy(_ => import("../../components/HomePageTabs/InboundLeadsStatus/InboundLeadsStatus.component"));
const AdminNbfcDetail = lazy(_ => import("../../components/AdminNbfcDetail/AdminNbfcDetail.component"));
const VendorFilePool = lazy(_ => import("../../components/HomePageTabs/VendorFilePool/VendorFilePool.component"));
const ReAssignLeads = lazy(_ => import("../../components/HomePageTabs/ReAssignLeads/ReAssignLeads.component"));
const InActiveUsers = lazy(_ => import("../../components/HomePageTabs/InActiveUsers/InActiveUsers.component"));
const ClosedLeads = lazy(_ => import("../../components/HomePageTabs/ClosedLeads/ClosedLeads.component"));
const NbfcManagerLeadStatus = lazy(_ => import("../../components/HomePageTabs/NbfcManagerLeadStatus/NbfcManagerLeadStatus.component"));

const HomePage = () => {

    const navigate = useNavigate()
    const location = useLocation().pathname
    const [userData, setUserData] = useContext(UserContext);
    const [isPendingTransition, startTransition] = useTransition();

    const handleUnload = async () => {
        await set(ref(firebaseDatabase, `USER_ARCHIVE/${userData.uid}/currently`), "Offline")
    }

    useEffect(() => {
        window.addEventListener('unload', handleUnload);

        return () => {
            window.removeEventListener('unload', handleUnload)
        }
    }, [])

    const handleActiveTab = (tab) => {
        startTransition(() => {
            navigate(`/${tab}`)
        })
    }


    const handleLogout = async () => {
        await set(ref(firebaseDatabase, `USER_ARCHIVE/${userData.uid}/currently`), "Offline")
        firebaseAuth.signOut()
        sessionStorage.clear();
    }

    // const handleCheckMessages = () => {
    //     sendWhatsappNotification.fileUpdatedByAdvisor("919845536638", "phone number: 91XXXXXXXXXX")
    // }

    if (!userData) {
        return (
            <div>
                <CircularProgress />
            </div>
        )
    }

    const handleChangeEmail = () => {
        // updateEmail(firebaseAuth.currentUser, "abhishek.chauhan@credila.com").then(()=>{
        //     alert("done")
        // }).catch((error)=>{
        //     alert(error)
        // })
    }

    return (
        <div className={Styles.homePageContainer}>
            <div className={Styles.homepageSideBarContainer}>
                <img src="white_logo_sc.svg" alt="student-cover" className={Styles.dp} />
                <p className={Styles.userName}>{userData.name}</p>
                <Divider style={{ color: 'white', marginTop: 40, backgroundColor: 'white' }} />

                {
                    userData.position === "Admin"
                    &&
                    <>
                        <div onClick={_ => handleActiveTab("admin-dashboard")} role='button' className={location.startsWith("/admin-dashboard") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/admin-dashboard") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/admin-dashboard") ? "#352F6C" : "#fff" }}>Home</p>
                        </div>

                        <div onClick={_ => handleActiveTab("manage-users")} role='button' className={location.startsWith("/manage-users") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <HiUserAdd size={20} color={location.startsWith("/manage-users") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/manage-users") ? "#352F6C" : "#fff" }}>Manage Users</p>
                        </div>
                    </>
                }

                {
                    userData.position === "Manager"
                    &&
                    <div onClick={_ => handleActiveTab("manage-leads")} role='button' className={location.startsWith("/manage-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                        <HiUserAdd size={20} color={location.startsWith("/manage-leads") ? "#352F6C" : "#fff"} />
                        <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/manage-leads") ? "#352F6C" : "#fff" }}>Manage Leads</p>
                    </div>
                }

                {
                    userData.rights && (userData.rights.includes("DistributeLeads") || userData.rights.includes("UploadLeads"))
                    &&
                    <>
                        <div onClick={_ => handleActiveTab("distribute-leads")} role='button' className={location.startsWith("/distribute-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/distribute-leads") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/distribute-leads") ? "#352F6C" : "#fff" }}>Distribute Leads</p>
                        </div>
                        
                    </>
                }

                {
                    userData.position === "Admin" && userData.department === "Eduation Loan"
                    &&
                    <>
                        <div onClick={_ => handleActiveTab("incognito-leads")} role='button' className={location.startsWith("/incognito-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <HiUserAdd size={20} color={location.startsWith("/incognito-leads") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/incognito-leads") ? "#352F6C" : "#fff" }}>Incognito Leads</p>
                        </div>
                        <div onClick={_ => handleActiveTab("lead-status")} role='button' className={location.startsWith("/lead-status") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <HiUserAdd size={20} color={location.startsWith("/lead-status") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/lead-status") ? "#352F6C" : "#fff" }}>Lead Status</p>
                        </div>
                        <div onClick={_ => handleActiveTab("inbound-leads")} role='button' className={location.startsWith("/inbound-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <HiUserAdd size={20} color={location.startsWith("/inbound-leads") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/inbound-leads") ? "#352F6C" : "#fff" }}>Inbound Leads</p>
                        </div>
                        <div onClick={_ => handleActiveTab("all-hi-lead-status")} role='button' className={location.startsWith("/all-hi-lead-status") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <HiUserAdd size={20} color={location.startsWith("/all-hi-lead-status") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/all-hi-lead-status") ? "#352F6C" : "#fff" }}>HI Lead Status</p>
                        </div>
                        <div onClick={_ => handleActiveTab("vendor-wise-leads")} role='button' className={location.startsWith("/vendor-wise-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <HiUserAdd size={20} color={location.startsWith("/vendor-wise-leads") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/vendor-wise-leads") ? "#352F6C" : "#fff" }}>Vendor Leads</p>
                        </div>
                        <div onClick={_ => handleActiveTab("bt-and-nd")} role='button' className={location.startsWith("/bt-and-nd") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <HiUserAdd size={20} color={location.startsWith("/bt-and-nd") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/bt-and-nd") ? "#352F6C" : "#fff" }}>BT and ND</p>
                        </div>
                        <div onClick={_ => handleActiveTab("re-assign-leads")} role='button' className={location.startsWith("/re-assign-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <HiUserAdd size={20} color={location.startsWith("/re-assign-leads") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/re-assign-leads") ? "#352F6C" : "#fff" }}>Reassign Leads</p>
                        </div>
                        <div onClick={_ => handleActiveTab("inactive-users")} role='button' className={location.startsWith("/inactive-users") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <HiUserAdd size={20} color={location.startsWith("/inactive-users") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/inactive-users") ? "#352F6C" : "#fff" }}>In-active Users</p>
                        </div>
                        <div onClick={_ => handleActiveTab("closed-leads")} role='button' className={location.startsWith("/closed-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <HiUserAdd size={20} color={location.startsWith("/closed-leads") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/closed-leads") ? "#352F6C" : "#fff" }}>Closed Leads</p>
                        </div>
                    </>
                }

                {
                    userData.position === "Advisor"
                    &&
                    <>
                        <div onClick={_ => handleActiveTab("advisor-dashboard")} role='button' className={location.startsWith("/advisor-dashboard") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <SlGraph size={20} color={location.startsWith("/advisor-dashboard") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/advisor-dashboard") ? "#352F6C" : "#fff" }}>Dashboard</p>
                        </div>
                        <div onClick={_ => handleActiveTab("advisor-leads")} role='button' className={location.startsWith("/advisor-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <FaPhone size={20} color={location.startsWith("/advisor-leads") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/advisor-leads") ? "#352F6C" : "#fff" }}>Calling Details</p>
                        </div>
                        <div onClick={_ => handleActiveTab("advisor-incognito-leads")} role='button' className={location.startsWith("/advisor-incognito-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <FaPhone size={20} color={location.startsWith("/advisor-incognito-leads") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/advisor-incognito-leads") ? "#352F6C" : "#fff" }}>Incognito Calling</p>
                        </div>
                        <div onClick={_ => handleActiveTab("future-intake")} role='button' className={location.startsWith("/future-intake") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <FaPhone size={20} color={location.startsWith("/future-intake") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/future-intake") ? "#352F6C" : "#fff" }}>Future Intake</p>
                        </div>
                        <div onClick={_ => handleActiveTab("cross-sale-leads")} role='button' className={location.startsWith("/cross-sale-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <FaBullhorn size={20} color={location.startsWith("/cross-sale-leads") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/cross-sale-leads") ? "#352F6C" : "#fff" }}>Cross Sale Leads</p>
                        </div>
                        <div onClick={_ => handleActiveTab("cross-sale-hi-operations")} role='button' className={location.startsWith("/cross-sale-hi-operations") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <FaBullhorn size={20} color={location.startsWith("/cross-sale-hi-operations") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/cross-sale-hi-operations") ? "#352F6C" : "#fff" }}>HI Operations</p>
                        </div>
                    </>
                }

                {
                    (userData.position === "Advisor" && userData.rights.includes("FeedbackCalls"))
                    &&
                    <div onClick={_ => handleActiveTab("feedback-calls")} role='button' className={location.startsWith("/feedback-calls") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                        <FaBullhorn size={20} color={location.startsWith("/feedback-calls") ? "#352F6C" : "#fff"} />
                        <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/feedback-calls") ? "#352F6C" : "#fff" }}>Feedback Calls</p>
                    </div>
                }

                {
                    userData.position === "Operations"
                    &&
                    <>
                        <div onClick={_ => handleActiveTab("operations-leads")} role='button' className={location.startsWith("/operations-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/operations-leads") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/operations-leads") ? "#352F6C" : "#fff" }}>New Leads</p>
                        </div>
                        <div onClick={_ => handleActiveTab("operations-incognito-leads")} role='button' className={location.startsWith("/operations-incognito-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                            <AiFillHome size={20} color={location.startsWith("/operations-incognito-leads") ? "#352F6C" : "#fff"} />
                            <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/operations-incognito-leads") ? "#352F6C" : "#fff" }}>Incognito Leads</p>
                        </div>
                    </>
                }

                {
                    (userData.position === "Operations" && userData.rights.includes("UploadDocuments"))
                    &&
                    <div onClick={_ => handleActiveTab("upload-docs")} role='button' className={location.startsWith("/upload-docs") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                        <FaBullhorn size={20} color={location.startsWith("/upload-docs") ? "#352F6C" : "#fff"} />
                        <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/upload-docs") ? "#352F6C" : "#fff" }}>Upload Documents</p>
                    </div>
                }

                {
                    userData.position === "NBFC"
                    &&
                    <>
                        {
                            userData.nbfcUserType === "Regional Manager"
                            ?
                            <div onClick={_ => handleActiveTab("nbfc-r-manager-file-status")} role='button' className={location.startsWith("/nbfc-r-manager-file-status") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                                <AiFillHome size={20} color={location.startsWith("/nbfc-r-manager-file-status") ? "#352F6C" : "#fff"} />
                                <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/nbfc-r-manager-file-status") ? "#352F6C" : "#fff" }}>File Status</p>
                            </div>
                            :
                            userData.nbfcUserType === "Manager"
                                ?
                                <>
                                    <div onClick={_ => handleActiveTab("vendor-file-pool")} role='button' className={location.startsWith("/vendor-file-pool") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                                        <AiFillHome size={20} color={location.startsWith("/vendor-file-pool") ? "#352F6C" : "#fff"} />
                                        <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/vendor-file-pool") ? "#352F6C" : "#fff" }}>File Pool</p>
                                    </div>
                                    <div onClick={_ => handleActiveTab("nbfc-manager-lead-status")} role='button' className={location.startsWith("/nbfc-manager-lead-status") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                                        <AiFillHome size={20} color={location.startsWith("/nbfc-manager-lead-status") ? "#352F6C" : "#fff"} />
                                        <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/nbfc-manager-lead-status") ? "#352F6C" : "#fff" }}>File Status</p>
                                    </div>
                                </>
                                :
                                <>
                                    <div onClick={_ => handleActiveTab("vendor-leads")} role='button' className={location.startsWith("/vendor-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                                        <AiFillHome size={20} color={location.startsWith("/vendor-leads") ? "#352F6C" : "#fff"} />
                                        <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/vendor-leads") ? "#352F6C" : "#fff" }}>New Files</p>
                                    </div>
                                    <div onClick={_ => handleActiveTab("vendor-fresh-leads")} role='button' className={location.startsWith("/vendor-fresh-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                                        <AiFillHome size={20} color={location.startsWith("/vendor-fresh-leads") ? "#352F6C" : "#fff"} />
                                        <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/vendor-fresh-leads") ? "#352F6C" : "#fff" }}>Fresh Login</p>
                                    </div>
                                    <div onClick={_ => handleActiveTab("vendor-on-hold-leads")} role='button' className={location.startsWith("/vendor-on-hold-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                                        <AiFillHome size={20} color={location.startsWith("/vendor-on-hold-leads") ? "#352F6C" : "#fff"} />
                                        <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/vendor-on-hold-leads") ? "#352F6C" : "#fff" }}>On-hold</p>
                                    </div>
                                    <div onClick={_ => handleActiveTab("vendor-re-login-leads")} role='button' className={location.startsWith("/vendor-re-login-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                                        <AiFillHome size={20} color={location.startsWith("/vendor-re-login-leads") ? "#352F6C" : "#fff"} />
                                        <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/vendor-re-login-leads") ? "#352F6C" : "#fff" }}>Re-login Leads</p>
                                    </div>
                                    <div onClick={_ => handleActiveTab("vendor-re-look-leads")} role='button' className={location.startsWith("/vendor-re-look-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                                        <AiFillHome size={20} color={location.startsWith("/vendor-re-look-leads") ? "#352F6C" : "#fff"} />
                                        <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/vendor-re-look-leads") ? "#352F6C" : "#fff" }}>Re-look</p>
                                    </div>
                                    <div onClick={_ => handleActiveTab("vendor-update-pf-leads")} role='button' className={location.startsWith("/vendor-update-pf-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                                        <AiFillHome size={20} color={location.startsWith("/vendor-update-pf-leads") ? "#352F6C" : "#fff"} />
                                        <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/vendor-update-pf-leads") ? "#352F6C" : "#fff" }}>Confirm PF & ROI</p>
                                    </div>
                                    <div onClick={_ => handleActiveTab("vendor-pf-paid-leads")} role='button' className={location.startsWith("/vendor-pf-paid-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                                        <AiFillHome size={20} color={location.startsWith("/vendor-pf-paid-leads") ? "#352F6C" : "#fff"} />
                                        <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/vendor-pf-paid-leads") ? "#352F6C" : "#fff" }}>PF Paid</p>
                                    </div>
                                    <div onClick={_ => handleActiveTab("vendor-sl-sent-leads")} role='button' className={location.startsWith("/vendor-sl-sent-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                                        <AiFillHome size={20} color={location.startsWith("/vendor-sl-sent-leads") ? "#352F6C" : "#fff"} />
                                        <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/vendor-sl-sent-leads") ? "#352F6C" : "#fff" }}>SL Sent</p>
                                    </div>
                                    <div onClick={_ => handleActiveTab("vendor-disbursement-leads")} role='button' className={location.startsWith("/vendor-disbursement-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                                        <AiFillHome size={20} color={location.startsWith("/vendor-disbursement-leads") ? "#352F6C" : "#fff"} />
                                        <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/vendor-disbursement-leads") ? "#352F6C" : "#fff" }}>Disbursement</p>
                                    </div>
                                    <div onClick={_ => handleActiveTab("vendor-closed-leads")} role='button' className={location.startsWith("/vendor-closed-leads") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                                        <AiFillHome size={20} color={location.startsWith("/vendor-closed-leads") ? "#352F6C" : "#fff"} />
                                        <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/vendor-closed-leads") ? "#352F6C" : "#fff" }}>Closed Leads</p>
                                    </div>
                                </>
                        }
                    </>
                }

                {
                    userData.position !== "NBFC"
                    &&
                    <div onClick={_ => handleActiveTab("resources")} role='button' className={location.startsWith("/resources") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                        <AiFillHome size={20} color={location.startsWith("/resources") ? "#352F6C" : "#fff"} />
                        <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/resources") ? "#352F6C" : "#fff" }}>Resources</p>
                    </div>
                }

                {
                    userData.position !== "NBFC"
                    &&
                    <div onClick={_ => handleActiveTab("add-single-lead")} role='button' className={location.startsWith("/add-single-lead") ? Styles.activeSideBarButton : Styles.inActiveSideBarButton}>
                        <AiFillHome size={20} color={location.startsWith("/add-single-lead") ? "#352F6C" : "#fff"} />
                        <p className={Styles.sideBarButtonText} style={{ color: location.startsWith("/add-single-lead") ? "#352F6C" : "#fff" }}>Add Lead</p>
                    </div>
                }

                <div className={Styles.inActiveSideBarButton}>
                    <AiOutlinePoweroff size={20} />
                    <p className={Styles.sideBarButtonText} onClick={() => { handleLogout() }}>Sign Out</p>
                </div>

                <div style={{ marginTop: 'auto', color: 'white', padding: 12, textAlign: 'center' }}>
                    <p>{LatestVersion}</p>
                </div>
            </div>
            <div className={Styles.homePageTabContentContainer}>
                {
                    (location === "/manage-users" && userData.position === "Admin")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <ManageUsers />
                    </Suspense>
                }
                {
                    (location === "/manage-users/add-user" && userData.position === "Admin")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <AddUser />
                    </Suspense>
                }
                {
                    (location === "/manage-users/edit-profile" && userData.position === "Admin")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <EditProfile />
                    </Suspense>
                }
                {
                    location === "/manage-leads"
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <ManagerLeads />
                    </Suspense>
                }
                {
                    location === "/manage-leads/manager-nbfc-detail"
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <ManagerNbfcDetail />
                    </Suspense>
                }
                {
                    (location === "/incognito-leads" && userData.position === "Admin")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <CredilaLeads />
                    </Suspense>
                }
                {
                    (location === "/distribute-leads" && (userData.position === "Admin" || userData.position === "Data Analyst"))
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <DistributeLeads />
                    </Suspense>
                }
                {
                    (location === "/lead-status" && userData.position === "Admin")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <AdminLeadStatus />
                    </Suspense>
                }
                {
                    (location === "/inbound-leads" && userData.position === "Admin")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <InboundLeadsStatus />
                    </Suspense>
                }
                {
                    (location === "/all-hi-lead-status" && userData.position === "Admin")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <HiAllLeadStatus />
                    </Suspense>
                }
                {
                    (location === "/all-hi-lead-status/complete-lead-detail" && userData.position === "Admin")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <HiAdminCompleteLeadDetail />
                    </Suspense>
                }
                {
                    (location === "/bt-and-nd" && userData.position === "Admin")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <BTandND />
                    </Suspense>
                }
                {
                    (location === "/vendor-wise-leads" && userData.position === "Admin")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <VendorWiseLeads />
                    </Suspense>
                }
                {
                    (location === "/re-assign-leads" && userData.position === "Admin")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <ReAssignLeads />
                    </Suspense>
                }
                {
                    (location === "/inactive-users" && userData.position === "Admin")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <InActiveUsers />
                    </Suspense>
                }
                {
                    (location === "/closed-leads" && userData.position === "Admin")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <ClosedLeads />
                    </Suspense>
                }
                {
                    (location === "/lead-status/admin-complete-detail" && userData.position === "Admin")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <AdminCompleteDetail />
                    </Suspense>
                }
                {
                    (location === "/lead-status/admin-nbfc-detail" && userData.position === "Admin")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <AdminNbfcDetail />
                    </Suspense>
                }
                {
                    location === "/advisor-leads"
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <AdvisorLeads />
                    </Suspense>
                }
                {
                    location === "/advisor-incognito-leads"
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <AdvisorIncognitoLeads />
                    </Suspense>
                }
                {
                    (location === "/future-intake" && userData.position === "Advisor")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <FutureIntake />
                    </Suspense>
                }
                {
                    location === "/future-intake/future-intake-lead-detail"
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <FutureIntakeLeadDetail />
                    </Suspense>
                }

                {
                    location === "/cross-sale-leads"
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <CrossSale />
                    </Suspense>
                }

                {
                    (location === "/feedback-calls" && userData.position === "Advisor" && userData.rights.includes("FeedbackCalls"))
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <FeedbackCalls />
                    </Suspense>
                }

                {
                    (location === "/upload-docs" && userData.position === "Operations" && userData.rights.includes("UploadDocuments"))
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <UploadDocs />
                    </Suspense>
                }

                {
                    (location === "/upload-docs/document-detail" && userData.position === "Operations" && userData.rights.includes("UploadDocuments"))
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <OperationsDocumentUploadPage />
                    </Suspense>
                }

                {
                    location === "/cross-sale-hi-operations"
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <CrossSaleOperationsLeads />
                    </Suspense>
                }

                {
                    location === "/cross-sale-leads/cross-sale-lead-detail"
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <CrossSaleLeadDetails />
                    </Suspense>
                }
                {
                    location === "/advisor-leads/advisor-lead-detail"
                    &&
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 1 }}>
                        <Suspense fallback={_ => <p>LOADING</p>}>
                            <AdvisorLeadDetail />
                        </Suspense>
                    </motion.div>
                }
                {
                    location === "/advisor-incognito-leads/advisor-incognito-lead-detail"
                    &&
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 1 }}>
                        <Suspense fallback={_ => <p>LOADING</p>}>
                            <AdvisorLeadDetailIncognito />
                        </Suspense>
                    </motion.div>
                }
                {
                    location === "/advisor-leads/advisor-case-detail"
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <AdvisorCaseDetail />
                    </Suspense>
                }
                
                {
                    location === "/advisor-incognito-leads/advisor-incognito-case-detail"
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <AdvisorCaseDetailIncognito />
                    </Suspense>
                }
                {
                    location === "/advisor-leads/advisor-complete-detail"
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <AdvisorCompleteDetail />
                    </Suspense>
                }
                {
                    location === "/advisor-incognito-leads/advisor-incognito-complete-detail"
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <AdvisorCompleteDetailIncognito />
                    </Suspense>
                }
                {
                    location === "/advisor-leads/advisor-nbfc-detail"
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <AdvisorNbfcDetail type={"Normal"} />
                    </Suspense>
                }
                {
                    location === "/advisor-incognito-leads/advisor-nbfc-detail-incognito"
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <AdvisorNbfcDetail type={"Incognito"} />
                    </Suspense>
                }
                {
                    (location === "/operations-leads" && userData.position === "Operations")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <OperationsLeads />
                    </Suspense>
                }
                {
                    (location === "/operations-incognito-leads" && userData.position === "Operations")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <OperationsIncognitoLeads />
                    </Suspense>
                }
                {
                    (location === "/operations-leads/operation-complete-detail" && userData.position === "Operations")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <OperationsCompleteDetail />
                    </Suspense>
                }
                {
                    (location === "/operations-incognito-leads/operation-complete-detail-incognito" && userData.position === "Operations")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <OperationsCompleteDetailIncognito />
                    </Suspense>
                }
                {
                    (location === "/operations-leads/operation-nbfc-detail" && userData.position === "Operations")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <OperationNbfcDetail type={"Normal"} />
                    </Suspense>
                }
                {
                    (location === "/operations-incognito-leads/operation-nbfc-detail-incognito" && userData.position === "Operations")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <OperationNbfcDetail type={"Incognito"} />
                    </Suspense>
                }
                {
                    (location === "/nbfc-r-manager-file-status" && userData.position === "NBFC")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <RegionalManagerLeadStatus />
                    </Suspense>
                }
                {
                    (location === "/vendor-file-pool" && userData.position === "NBFC")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <VendorFilePool />
                    </Suspense>
                }
                {
                    (location === "/nbfc-manager-lead-status" && userData.position === "NBFC")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <NbfcManagerLeadStatus />
                    </Suspense>
                }
                {
                    (location === "/vendor-leads" && userData.position === "NBFC")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <VendorLeads />
                    </Suspense>
                }
                {
                    (location === "/vendor-leads/vendor-login-type" && userData.position === "NBFC")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <VendorLoginType />
                    </Suspense>
                }
                {
                    (location === "/vendor-fresh-leads" && userData.position === "NBFC")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <VendorFreshLeads />
                    </Suspense>
                }
                {
                    (location === "/vendor-on-hold-leads" && userData.position === "NBFC")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <VendorOnHoldLeads />
                    </Suspense>
                }
                {
                    (location === "/vendor-on-hold-leads/vendor-complete-detail" && userData.position === "NBFC")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <VendorCompleteDetail />
                    </Suspense>
                }

                {
                    ((location === "/vendor-fresh-leads/vendor-complete-detail" || location === "/vendor-re-look-leads/vendor-complete-detail") && userData.position === "NBFC")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <VendorCompleteDetail />
                    </Suspense>
                }
                
                {
                    (location === "/vendor-re-login-leads" && userData.position === "NBFC")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <VendorReLoginLeads />
                    </Suspense>
                }
                {
                    (location === "/vendor-re-look-leads" && userData.position === "NBFC")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <VendorReLookLeads />
                    </Suspense>
                }
                {
                    (location === "/vendor-update-pf-leads" && userData.position === "NBFC")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <VendorUpdatePfLeads />
                    </Suspense>
                }
                {
                    (location === "/vendor-update-pf-leads/add-pf-roi" && userData.position === "NBFC")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <VendorAddPfRoi />
                    </Suspense>
                }
                {
                    (location === "/vendor-pf-paid-leads" && userData.position === "NBFC")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <VendorPfPaidLeads />
                    </Suspense>
                }
                {
                    (location === "/vendor-sl-sent-leads" && userData.position === "NBFC")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <VendorSlSentLeads />
                    </Suspense>
                }
                {
                    (location === "/vendor-disbursement-leads" && userData.position === "NBFC")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <VendorDisbursementLeads />
                    </Suspense>
                }
                {
                    (location === "/vendor-closed-leads" && userData.position === "NBFC")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <VendorClosedLeads />
                    </Suspense>
                }
                {
                    (location === "/vendor-view-file" && userData.position === "NBFC")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <VendorViewFile />
                    </Suspense>
                }

                {
                    (location === "/call-stats" && userData.position === "Admin")
                    &&
                    <Suspense fallback={_ => <p>LOADING</p>}>
                        <CallStats />
                    </Suspense>
                }

                {
                    location === "/add-single-lead"
                    &&
                    <AddSingleLead />
                }

                {
                    location === "/advisor-dashboard"
                    &&
                    <AdvisorDashboard />
                }

                {
                    (location === "/admin-dashboard" && userData.position === "Admin")
                    &&
                    <AdminDasbhoard />
                }

                {
                    (location === "/sc-view-file")
                    &&
                    <ScViewFile />
                }

                {
                    (location === "/resources")
                    &&
                    <ResourcesTab />
                }
            </div>
            {/* <Button onClick={handleCheckMessages}>CHECK MESSAGES</Button> */}
        </div>
    )
}

export default HomePage