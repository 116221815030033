import React, { useContext, useEffect, useState } from "react";
import Styles from "./AdvisorLeadDetailIncognito.module.css"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import QRCode from "react-qr-code";
import { Alert, Box, Button, Checkbox, FormControlLabel, MenuItem, Modal, Select, Snackbar, Switch, Tab, Tabs } from "@mui/material";
import relationMap from "../../workload/CoSignerRelationMap";
import { off, onValue, ref, remove, set } from "firebase/database";
import { firebaseDatabase, firebaseDatabaseSC, firebaseStorage } from "../../backend/firebaseHandler";
import UserContext from "../../context/UserData.context";
import moment from "moment";
import { FaExternalLinkSquareAlt } from "react-icons/fa"
import { getDownloadURL, ref as storageRef, uploadBytesResumable } from "firebase/storage";
import CryptoJS from "crypto-js";
import getDocumentMap from "../../workload/DocumentSet";
import { AiFillCloseSquare } from "react-icons/ai"
import InputContainer from "../InputContainer/InputContainer.component";
import isEmail from "validator/lib/isEmail";
import { checkIfUserActive } from "../../workload/UserAccountStatus";
import _ from 'lodash';
import getNbfcList from "../../workload/NbfcList";

const AdvisorLeadDetailIncognito = () => {

    const location = useLocation()
    const navigate = useNavigate();

    const [userData, setUserData] = useContext(UserContext)
    const [application, setApplication] = useState(location.state.item);

    const [value, setValue] = React.useState(0);
    const [submitting, setSubmitting] = useState(false)
    const [moreOptionsVisible, setMoreOptionsVisible] = useState(false)
    const [rechurnUsers, setRechurnUser] = useState([])
    const [snackBar, setSnackBar] = useState({ visibility: false, severity: "", message: "" })

    const [examsWritten, setExamsWritten] = useState([])
    const [reLookIn, setReLookIn] = useState([])

    const [accountExists, setAccountExists] = useState("No")
    const [accountModal, setAccountModal] = useState(false)

    const [dateTimeModal, setDateTimeModal] = useState(false)
    const [rescheduleDateTime, setRescheduleDateTime] = useState({ "date": "", "time": "" })

    const [languageModal, setLanguageModal] = useState(false)
    const languageList = ["Hindi", "English", "Punjabi", "Marathi", "Kannada", "Tamil", "Telugu"]
    const [language, setLanguage] = useState("")

    const [qrLink, setQrLink] = useState("")
    const mandatoryDocs = ["Aadhar Card", "Pan Card", "Last 2 year ITR", "Last 6 month Bank statement Savings Account", "Last 6 month Bank statement Current Account", "Company Pan", "COI", "MOA", "AOA", "Permanent Address Proof"]

    const [docNameModal, setDocNameModal] = useState(false)
    const [docName, setDocName] = useState("")

    const [otherRelationModal, setOtherRelationModal] = useState(false)
    const [otherRelationName, setOtherRelationName] = useState("")

    const [outstandingModal, setOutstandingModal] = useState(false);
    const [notDoableModal, setNotDoableModal] = useState(false)

    const [moreOptionsModal, setMoreOptionsModal] = useState(false)
    const [moreOptionsReason, setMoreOptionsReason] = useState("")
    const [selectedDisposition, setSelectedDisposition] = useState(false)
    
    const [futureSemester, setFutureSemester] = useState("")
    const [futureYear, setFutureYear] = useState("")
    const [futureIntakeModal, setFutureIntakeModal] = useState(false)

    useEffect(() => {
        onValue(ref(firebaseDatabaseSC, `USERS_ARCHIVE/${application.phoneNumber}`), (snapShot) => {
            if (snapShot.exists()) {
                setAccountExists("Yes")
            } else {
                setAccountExists("No")
            }
        }, { onlyOnce: true })

        const filterActiveUser = async () => {
            let rechurnTempUser = []
            for (const [index, item] of application.advisorPool.entries()) {
                if (item.uid !== userData.uid) {
                    const status = await checkIfUserActive(item.uid)
                    if (status === "Active") {
                        rechurnTempUser.push(item)
                    }
                }
            }
            setRechurnUser(rechurnTempUser)
        }
        filterActiveUser()




        if (application.examsWritten) {
            setExamsWritten(application.examsWritten)
        }

        if (application.reLookIn) {
            setReLookIn(application.reLookIn)
        }

        console.log(userData.uid, application.phoneNumber)
        switch (application.status) {
            case "New": {
                setQrLink(encrypt(`INCOGNITO_NEW_LEADS/${userData.uid}/${application.phoneNumber}`, "CutriPlingPutri"))
                break;
            }
            case "Interested": {
                setQrLink(encrypt(`INCOGNITO_INTERESTED_LEADS/${userData.uid}/${application.phoneNumber}`, "CutriPlingPutri"))
                break;
            }
            case "Case Details": {
                setQrLink(encrypt(`INCOGNITO_CASE_DETAILS_LEADS/${userData.uid}/${application.phoneNumber}`, "CutriPlingPutri"))
                break;
            }
            default: { }
        }

        const leadRef = ref(firebaseDatabase, `INCOGNITO_NEW_LEADS/${userData.uid}/${application.phoneNumber}`)

        onValue(leadRef, (snap) => {
            if (snap.exists()) {

                const latestApplication = snap.val();
                setApplication(latestApplication);

            }
        })

        return () => {
            off(leadRef, "value");
        }

    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    function encrypt(message, key) {
        return CryptoJS.AES.encrypt(message, key).toString();
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const checkDialogContent = () => {

        if (application.name === "") {
            setSnackBar({ visibility: true, severity: "warning", message: "Please enter the customer's name" })
            return
        }
        if (application.emailId === "") {
            setSnackBar({ visibility: true, severity: "warning", message: "Please enter the customer's email-Id" })
            return
        }

        if (!isEmail(application.emailId)) {
            setSnackBar({ visibility: true, severity: "warning", message: "Please enter a valid email-Id" })
            return
        }

        setAccountModal(true)
    }

    const moveToInterested = async () => {

        if (application.coApplicantProfession !== "" && application.coApplicantAge !== "") {
            if (application.coApplicantProfession === "Pensioner" && parseInt(application.coApplicantAge) > 58) {
                setSnackBar({ visibility: true, severity: "warning", message: "Sorry! If the co-applicant is a Pensioner, the age can not be more than 58." })
                return
            }
        }

        if (application.coApplicant && application.coApplicant === "Other" && (!application.otherCoApplicant || application.otherCoApplicant==="")) {
            setSnackBar({ visibility: true, severity: "warning", message: "Please specify the co-applicant relation." })
            return
        }

        setSubmitting(true)

        let tempObj = { ...application }

        tempObj.status = "Interested"
        tempObj.interestedDate = moment().format("DD-MM-YYYY h:mm a")
        tempObj.examsWritten = [...examsWritten]
        tempObj.reLookIn = [...reLookIn]
        tempObj.customerId = Date.now().toString()

        
        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let lifelineObj = snap.val()
                lifelineObj.status = "Interested"

                let array = [...lifelineObj.lifeline]
                let arrayItem = {
                    actionBy: userData.uid,
                    status: "Interested",
                    subStatus: "",
                    date: tempObj.interestedDate,
                    ref: "INCOGNITO_INTERESTED_LEADS"
                }
                array.push(arrayItem)
                lifelineObj.lifeline = [...array]
                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), lifelineObj)

                if (accountExists === "No") {
                    let tempAccObj = {}

                    tempAccObj = {
                        ...tempAccObj,
                        "createdBy": userData.uid,
                        "emailId": tempObj.emailId,
                        "isSignup": true,
                        "name": tempObj.name,
                        "otp": "",
                        "otpSent": "",
                        "phoneNumber": tempObj.phoneNumber,
                        "signupTimestamp": ""
                    }
                    await set(ref(firebaseDatabaseSC, `USERS_ARCHIVE/${tempAccObj.phoneNumber}`), tempAccObj)
                }

                await set(ref(firebaseDatabase, `INCOGNITO_INTERESTED_LEADS/${userData.uid}/${application.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), tempObj)
                await remove(ref(firebaseDatabase, `INCOGNITO_NEW_LEADS/${userData.uid}/${application.phoneNumber}`));
                setSubmitting(false)
                setAccountModal(false)
                setQrLink(encrypt(`INCOGNITO_INTERESTED_LEADS/${userData.uid}/${application.phoneNumber}`, "CutriPlingPutri"))
                setApplication({ ...tempObj })
            }
        }, { onlyOnce: true })
    }

    const moveToClosed = async (source, destination) => {
        let tempObj = { ...application }

        if (destination === "Not Doable" && (!tempObj.notDoableReason || tempObj.notDoableReason === "")) {
            setSnackBar({visibility:true, severity:"warning", message:"Please provide the reason."})
            return
        }

        setSubmitting(true)

        tempObj.status = "Closed"
        tempObj.subStatus = destination
        tempObj.closedDate = moment().format("DD-MM-YYYY h:mm a")

        
        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let lifelineObj = snap.val()
                lifelineObj.status = "Closed"

                let array = [...lifelineObj.lifeline]
                let arrayItem = {
                    actionBy: userData.uid,
                    status: "Closed",
                    subStatus: destination,
                    date: tempObj.closedDate,
                    ref: "INCOGNITO_CLOSED_LEADS"
                }
                array.push(arrayItem)
                lifelineObj.lifeline = [...array]
                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), lifelineObj)
                await set(ref(firebaseDatabase, `INCOGNITO_CLOSED_LEADS/${userData.uid}/${tempObj.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), tempObj)
        
                if (source === "New") {
                    await remove(ref(firebaseDatabase, `INCOGNITO_NEW_LEADS/${userData.uid}/${tempObj.phoneNumber}`))
                } else if (source === "Sent to Vendor") {
                    // await remove(ref(firebaseDatabase, `WITH_VENDORS_LEADS/${userData.uid}/${tempObj.phoneNumber}`))
                }
                setSubmitting(false);
                navigate(-1)
            }
        }, { onlyOnce: true })
    }

    const moveToReminder = async () => {
        setSubmitting(true)
        let tempObj = { ...application }

        tempObj.subStatus = "Rescheduled"
        tempObj.rescheduledDate = moment().format("DD-MM-YYYY h:mm a")

        tempObj["nextCallDateTime"] = rescheduleDateTime.date + " " + rescheduleDateTime.time

        
        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let lifelineObj = snap.val()

                let array = [...lifelineObj.lifeline]
                let arrayItem = {
                    actionBy: userData.uid,
                    status: "New",
                    subStatus: "Rescheduled",
                    date: moment().format("DD-MM-YYYY h:mm a"),
                    ref: "INCOGNITO_NEW_LEADS"
                }
                array.push(arrayItem)
                lifelineObj.lifeline = [...array]
                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), lifelineObj)
                await set(ref(firebaseDatabase, `INCOGNITO_NEW_LEADS/${userData.uid}/${application.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), tempObj)
                setSubmitting(false)
                navigate(-1)
            }
        }, { onlyOnce: true })
    }

    const moveToFutureIntake = (intake) => {
        if (futureSemester === "" || futureYear === "") {
            setSnackBar({visibility:true, severity:"warning", message:"Please select both semester and year"})
            return
        }
        
        setSubmitting(true)
        let tempObj = { ...application }

        tempObj.subStatus = "Future Intake"
        tempObj.futureIntakeDate = moment().format("DD-MM-YYYY h:mm a")
        tempObj.futureIntakeType = intake
        
        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let lifelineObj = snap.val()

                let array = [...lifelineObj.lifeline]
                let arrayItem = {
                    actionBy: userData.uid,
                    status: "New",
                    subStatus: "Future Intake",
                    date: moment().format("DD-MM-YYYY h:mm a"),
                    ref: "NEW_LEADS"
                }
                array.push(arrayItem)
                lifelineObj.lifeline = [...array]
                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), lifelineObj)
                await set(ref(firebaseDatabase, `INCOGNITO_NEW_LEADS/${userData.uid}/${application.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), tempObj)
                // await sendWhatsappNotification.actionMarkedRescheduled(application.phoneNumber, application.name, "")
                setSubmitting(false)
                navigate(-1)
            }
        }, { onlyOnce: true })
    }

    const handleChurning = async () => {

        if (rechurnUsers.length === 0) {
            moveToClosed("New", "No Response")
            return
        }

        let tempObj = { ...location.state.item }
        let advisorPool = [...rechurnUsers]
        let newAdvisor = advisorPool[Math.floor(Math.random() * advisorPool.length)]
        let pastAdvisors = tempObj.pastAdvisors ? tempObj.pastAdvisors : []
        pastAdvisors.push(userData.uid)

        if (pastAdvisors.length > 5) {
            moveToClosed("New", "No Response")
            return
        }

        setSubmitting(true)

        tempObj.subStatus = "From Admin-Yet to Call"
        tempObj.newFromAdminDate = moment().format('DD-MM-YYYY h:mm a')
        tempObj.pastAdvisors = pastAdvisors
        if (newAdvisor.uid !== userData.uid) {
            tempObj.assignedAdvisorUid = newAdvisor.uid
            tempObj.assignedAdvisorName = newAdvisor.name
            tempObj.assignedAdvisorPhoneNumber = newAdvisor.phoneNumber
            await set(ref(firebaseDatabase, `INCOGNITO_NEW_LEADS/${newAdvisor.uid}/${tempObj.phoneNumber}`), tempObj)
            await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), tempObj)
            await remove(ref(firebaseDatabase, `INCOGNITO_NEW_LEADS/${userData.uid}/${tempObj.phoneNumber}`))
        } else {
            await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), tempObj)
            await set(ref(firebaseDatabase, `INCOGNITO_NEW_LEADS/${userData.uid}/${tempObj.phoneNumber}`), tempObj)
        }

        // await remove(ref(firebaseDatabase, `CALL_STATUS/${tempObj.phoneNumber}`))
        setSubmitting(false)
        navigate(-1)

    }

    const handleLanguageSelect = async () => {
        let userList = []

        setSubmitting(true)
        onValue(ref(firebaseDatabase, `USER_ARCHIVE`), async (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    let user = snap.child(key).val()

                    if (user.languages) {
                        if (user.position === "Advisor" && user.languages.includes(language) && user.department === "Education Loan") {
                            userList.push(user)
                        }
                    }
                }
                if (userList.length !== 0) {
                    let newAdvisor = userList[Math.floor(Math.random() * userList.length)]
                    let tempObj = { ...application }
                    if (newAdvisor.uid !== userData.uid) {
                        tempObj.assignedAdvisorUid = newAdvisor.uid
                        tempObj.assignedAdvisorName = newAdvisor.name
                        tempObj.assignedAdvisorPhoneNumber = newAdvisor.phoneNumber
                        tempObj.subStatus = "Forwarded - " + language
                        tempObj.forwardedBy = { "position": "Advisor", "name": userData.name, "phoneNumber": userData.phoneNumber, "uid": userData.uid }
                        await set(ref(firebaseDatabase, `INCOGNITO_NEW_LEADS/${newAdvisor.uid}/${tempObj.phoneNumber}`), tempObj)
                        await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), tempObj)
                        await remove(ref(firebaseDatabase, `INCOGNITO_NEW_LEADS/${userData.uid}/${tempObj.phoneNumber}`))
                    }

                    onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), async (snap) => {
                        if (snap.exists()) {
                            let lifelineObj = snap.val()

                            let array = [...lifelineObj.lifeline]
                            let arrayItem = {
                                actionBy: userData.uid,
                                status: "New",
                                subStatus: "Reassigned due to language - " + language,
                                date: moment().format("DD-MM-YYYY h:mm a"),
                                ref: "INCOGNITO_NEW_LEADS"
                            }
                            array.push(arrayItem)
                            lifelineObj.lifeline = [...array]
                            await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), lifelineObj)
                            setSubmitting(false)
                            navigate(-1)
                        }
                    }, { onlyOnce: true })
                } else {
                    let tempObj = { ...application }

                    tempObj.status = "Closed"
                    tempObj.subStatus = "Language barrier - " + language

                    
                    onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), async (snap) => {
                        if (snap.exists()) {
                            let lifelineObj = snap.val()
                            lifelineObj.status = "Closed"

                            let array = [...lifelineObj.lifeline]
                            let arrayItem = {
                                actionBy: userData.uid,
                                status: "Closed",
                                subStatus: "Language barrier - " + language,
                                date: moment().format("DD-MM-YYYY h:mm a"),
                                ref: "INCOGNITO_CLOSED_LEADS"
                            }
                            array.push(arrayItem)
                            lifelineObj.lifeline = [...array]
                            await set(ref(firebaseDatabase, `INCOGNITO_CLOSED_LEADS/${userData.uid}/${tempObj.phoneNumber}`), tempObj)
                            await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${tempObj.phoneNumber}`), tempObj)

                            await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${tempObj.phoneNumber}`), lifelineObj)
                            await remove(ref(firebaseDatabase, `INCOGNITO_NEW_LEADS/${userData.uid}/${tempObj.phoneNumber}`))
                            setSubmitting(false)
                            navigate(-1)
                        }
                    }, { onlyOnce: true })
                }
            }
        }, { onlyOnce: true })
    }

    const handleSave = async () => {
        application.examsWritten = [...examsWritten]
        application.reLookIn = [...reLookIn]

        if (application.coApplicantProfession !== "" && application.coApplicantAge !== "") {
            if (application.coApplicantProfession === "Pensioner" && parseInt(application.coApplicantAge) > 58) {
                setSnackBar({ visibility: true, severity: "warning", message: "Sorry! If the co-applicant is a Pensioner, the age can not be more than 58." })
                return
            }
        }

        if (application.coApplicant && application.coApplicant === "Other" && (!application.otherCoApplicant || application.otherCoApplicant==="")) {
            setSnackBar({ visibility: true, severity: "warning", message: "Please specify the co-applicant relation." })
            return
        }
        let remainingDocs = false
        if (!application.applicantDocs || !application.coApplicantDocs || !application.nonFinancialDocs) {
            remainingDocs = true
        } else {
            let businessProofPresent = true
            let firstPicPresent = true
            let secondPicPresent = true
            let thirdPicPresent = true
            let fourthPicPresent = true
            for (const index in application.applicantDocs) {
                let currentDoc = application.applicantDocs[index]
                if (currentDoc.name === "Graduation Mark Sheet") {
                    if (application.levelOfStudy !== "UG" && application.applicantDocs[index].url === "") {
                        remainingDocs = true
                    }
                } else if (currentDoc.name === "Transcript") {
                    if (application.levelOfStudy !== "UG" && application.applicantDocs[index].url === "") {
                        remainingDocs = true
                    }
                } else if (currentDoc.name === "Provisional Certificate") {
                    // if (application.levelOfStudy!=="UG" && application.applicantDocs[index].url === "") {
                    //     remainingDocs = true
                    // }
                } else {
                    if (application.applicantDocs[index].url === "") {
                        remainingDocs = true
                    }
                }

            }
            for (const index in application.examDocs) {
                if (application.examDocs[index].url === "") {
                    remainingDocs = true
                }
            }
            if (application.coApplicantProfession === "Self Employed - Professional" || application.coApplicantProfession === "Self Employed - Non Professional") {
                for (const index in application.coApplicantDocs) {
                    let currentItem = application.coApplicantDocs[index]
                    if (mandatoryDocs.includes(currentItem.name) && currentItem.url === "") {
                        remainingDocs = true
                    }
                    if (currentItem.name === "Business Proof" && currentItem.url === "") {
                        businessProofPresent = false
                    }
                    if (currentItem.name === "1st Picture of Business" && currentItem.url === "") {
                        firstPicPresent = false
                    }
                    if (currentItem.name === "2nd Picture of Business" && currentItem.url === "") {
                        secondPicPresent = false
                    }
                    if (currentItem.name === "3rd Picture of Business" && currentItem.url === "") {
                        thirdPicPresent = false
                    }
                    if (currentItem.name === "4th Picture of Business" && currentItem.url === "") {
                        fourthPicPresent = false
                    }
                }
            } else {
                for (const index in application.coApplicantDocs) {
                    if (application.coApplicantDocs[index].name !== "Property Documents") {
                        if (application.coApplicantDocs[index].url === "") {
                            remainingDocs = true
                        }
                    }
                }
            }

            if (!(businessProofPresent || (firstPicPresent && secondPicPresent && thirdPicPresent && fourthPicPresent))) {
                remainingDocs = true
            }
            for (const index in application.abroadCoAppDocs) {
                if (application.abroadCoAppDocs[index].url === "") {
                    remainingDocs = true
                }
            }
            for (const index in application.nonFinancialDocs) {
                for (const index2 in application.nonFinancialDocs[index]) {
                    if (application.nonFinancialDocs[index][index2].url === "") {
                        remainingDocs = true
                    }
                }
            }
            for (const index in application.locationGuarantorDocs) {
                if (application.locationGuarantorDocs[index].url === "") {
                    remainingDocs = true
                }
            }
            for (const index in application.abroadDocs) {
                if (application.abroadDocs[index].url === "") {
                    remainingDocs = true
                }
            }
            for (const index in application.abroadDocs) {
                if (application.abroadDocs[index].url === "") {
                    remainingDocs = true
                }
            }
        }

        if (remainingDocs) {
            // alert("Save")
            // return

            setSubmitting(true)
            await set(ref(firebaseDatabase, `INCOGNITO_INTERESTED_LEADS/${userData.uid}/${application.phoneNumber}`), application)
            await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), application)
            setSnackBar({ visibility: true, severity: "success", message: "Changes saved!" })
            setSubmitting(false)
        } else {
            // alert("CD")
            // return

            setSubmitting(true)
            let tempObj = { ...application }

            tempObj.status = "Case Details"
            tempObj.caseDetailDate = moment().format("DD-MM-YYYY h:mm a")
            tempObj.examsWritten = [...examsWritten]
            tempObj.reLookIn = [...reLookIn]
            tempObj.isLodVerified = "No"

            
            onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), async (snap) => {
                if (snap.exists()) {
                    let lifelineObj = snap.val()
                    lifelineObj.status = "Case Details"

                    let array = [...lifelineObj.lifeline]
                    let arrayItem = {
                        actionBy: userData.uid,
                        status: "Case Details",
                        subStatus: "",
                        date: tempObj.caseDetailDate,
                        ref: "CASE_DETAILS_LEADS"
                    }
                    array.push(arrayItem)
                    lifelineObj.lifeline = [...array]
                    await set(ref(firebaseDatabase, `INCOGNITO_CASE_DETAILS_LEADS/${userData.uid}/${application.phoneNumber}`), tempObj)
                    await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), tempObj)

                    await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), lifelineObj)
                    await remove(ref(firebaseDatabase, `INCOGNITO_INTERESTED_LEADS/${userData.uid}/${application.phoneNumber}`))
                    setApplication({ ...tempObj })
                    setSnackBar({ visibility: true, severity: "success", message: "Lead moved to Case Details!" })
                    setTimeout(() => {
                        setSubmitting(false)
                        navigate(-1)
                    }, 3000)
                }
            }, { onlyOnce: true })
        }
    }

    const handleLodSave = async () => {
        setSubmitting(true)
        await set(ref(firebaseDatabase, `INCOGNITO_CASE_DETAILS_LEADS/${userData.uid}/${application.phoneNumber}`), application)
        await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), application)
        setSnackBar({ visibility: true, severity: "success", message: "Changes saved!" })
        setSubmitting(false)
    }

    const handleVerifyLod = async () => {
        let tempObj = { ...application }
        tempObj.isLodVerified = "Yes"
        tempObj.lodVerifiedDate = moment().format("DD-MM-YYYY h:mm a")
        setSubmitting(true)
        
        onValue(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), async (snap) => {
            if (snap.exists()) {
                let lifelineObj = snap.val()

                let array = [...lifelineObj.lifeline]
                let arrayItem = {
                    actionBy: userData.uid,
                    status: "Case Details",
                    subStatus: "LOD Verified",
                    date: tempObj.lodVerifiedDate,
                    ref: "INCOGNITO_CASE_DETAILS_LEADS"
                }
                array.push(arrayItem)
                lifelineObj.lifeline = [...array]
                await set(ref(firebaseDatabase, `ALL_LEADS_STATUS/Incognito/${application.phoneNumber}`), lifelineObj)
                await set(ref(firebaseDatabase, `INCOGNITO_CASE_DETAILS_LEADS/${userData.uid}/${application.phoneNumber}`), tempObj)
                await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), tempObj)

                setSnackBar({ visibility: true, severity: "success", message: "LOD marked verified!" })
                setTimeout(() => {
                    setSubmitting(false)
                    navigate(-1)
                }, 3000)
            }
        }, { onlyOnce: true })
    }

    const handleCoApplicantSelect = (value) => {
        let tempObj = { ...application }
        tempObj.coApplicant = value
        tempObj.coApplicantProfession = ""
        tempObj.coApplicantAge = ""
        tempObj.coApplicantDocs = null
        tempObj.whyFatherNotFin = ""
        tempObj.whyMotherNotFin = ""
        tempObj.coApplicantInIndia = ""
        tempObj.nonFinancialCoApplicants = [...relationMap[value]]

        let tempDocs = []
        for (const index in tempObj.nonFinancialCoApplicants) {
            tempDocs.push(getDocumentMap()["NonFinancial"])
        }

        tempObj.nonFinancialDocs = [...tempDocs]
        setApplication({ ...tempObj })
    }

    const handleProfession = (value) => {

        let tempObj = { ...application }
        tempObj.coApplicantProfession = value

        let searchWord = ""

        if (["Father", "Mother", "Spouse", "Father-in-law", "Mother-in-law", "Sister", "Brother"].includes(application.coApplicant)) {
            searchWord = "Direct"
        } else {
            searchWord = "InDirect"
        }
        tempObj.coApplicantDocs = [...getDocumentMap()[searchWord][value]]

        setApplication({ ...tempObj })
    }

    const handleWorkExperience = (value) => {
        setApplication({ ...application, hasWorkExperience: value })

        let tempObj = { ...application }
        tempObj.hasWorkExperience = value

        if (value === "Yes") {
            tempObj.applicantDocs = [...getDocumentMap()["StudentWorking"]]
        } else if (value === "No") {
            tempObj.applicantDocs = [...getDocumentMap()["StudentNonWorking"]]
        } else {
            tempObj.applicantDocs = [...getDocumentMap()["StudentUsedToWork"]]
        }

        setApplication(_ => ({ ...tempObj }))
    }

    const handleFatherReason = (value) => {
        let tempObj = { ...application }
        tempObj.whyFatherNotFin = value

        if (value === "Poor Cibil" || value === "No Income Proof or Low banking") {
            tempObj.nonFinancialDocs[0] = [...getDocumentMap()["NonFinancial"]]
        } else if (value === "Divorced") {
            tempObj.nonFinancialDocs[0] = [...getDocumentMap()["SeparatedFather"]]
        } else if (value === "Deceased") {
            tempObj.nonFinancialDocs[0] = [...getDocumentMap()["DeadFather"]]
        }

        setApplication({ ...tempObj })
    }

    const handleMotherReason = (value) => {
        let tempObj = { ...application }
        tempObj.whyMotherNotFin = value

        if (value === "Poor Cibil" || value === "No Income Proof or Low banking") {
            tempObj.nonFinancialDocs[1] = [...getDocumentMap()["NonFinancial"]]
        } else if (value === "Divorced") {
            tempObj.nonFinancialDocs[1] = [...getDocumentMap()["SeparatedMother"]]
        } else if (value === "Deceased") {
            tempObj.nonFinancialDocs[1] = [...getDocumentMap()["DeadMother"]]
        }

        setApplication({ ...tempObj })
    }

    const handleExams = (value) => {
        setExamsWritten(value)

        let tempArray = []
        let tempObj = { ...application }
        for (const index in value) {
            let obj = {
                name: value[index],
                url: "",
                label: value[index]
            }
            tempArray.push(obj)
        }
        tempObj["examDocs"] = [...tempArray]
        setApplication({ ...tempObj })
    }

    const handleDocumentUpload = (item, folderName, index, key) => {
        const inputElement = document.createElement('input');
        inputElement.setAttribute('type', "file");
        inputElement.setAttribute('accept', 'application/pdf');
        inputElement.onchange = (event) => {
            const files = event.target.files;

            let name = files[0].name
            let nameArray = name.split(".")
            let lastItem = nameArray[nameArray.length - 1]
            if (lastItem !== "pdf") {
                setSnackBar({ visibility: true, severity: "warning", message: "Please upload a PDF file." })
            } else {
                const fileRef = storageRef(firebaseStorage, `EDUCATION_LOAN_DOCS/${application.key}/${folderName}/${item.label}/${Date.now().toString()}.pdf`);
                setSubmitting(true)
                const uploadTask = uploadBytesResumable(fileRef, files[0], { contentType: 'application/pdf' });
                uploadTask.on('state_changed', (snapshot) => {

                }, (error) => {
                    setSnackBar({ visibility: true, severity: "error", message: "Something went wrong" })
                }, async () => {
                    const url = await getDownloadURL(fileRef);
                    item.url = url
                    let tempObj = { ...application }
                    tempObj[key][index] = { ...item }
                    setApplication({ ...tempObj })
                    setSubmitting(false)
                })
            }
        }
        inputElement.click();

        // item.url = "url"
        // let tempObj = {...application}
        // tempObj[key][index] = {...item}
        // setApplication({...tempObj})
        // setSubmitting(false)
    }

    const handleNestedDocumentUpload = (item, folderName, index, index2, key) => {
        const inputElement = document.createElement('input');
        inputElement.setAttribute('type', "file");
        inputElement.setAttribute('accept', 'application/pdf');
        inputElement.onchange = (event) => {
            const files = event.target.files;

            if (files[0].name.split(".")[1] !== "pdf") {
                setSnackBar({ visibility: true, severity: "warning", message: "Please upload a PDF file." })
            } else {
                const fileRef = storageRef(firebaseStorage, `EDUCATION_LOAN_DOCS/${application.key}/${folderName}/${item.label}/${Date.now().toString()}`);
                setSubmitting(true)
                const uploadTask = uploadBytesResumable(fileRef, files[0], { contentType: 'application/pdf' });
                uploadTask.on('state_changed', (snapshot) => {

                }, (error) => {
                    setSnackBar({ visibility: true, severity: "error", message: "Something went wrong" })
                }, async () => {
                    const url = await getDownloadURL(fileRef);
                    item.url = url
                    let tempObj = { ...application }
                    tempObj[key][index][index2] = { ...item }
                    setApplication({ ...tempObj })
                    setSubmitting(false)
                })
            }
        }
        inputElement.click();

        // item.url = "url"
        // let tempObj = {...application}
        // tempObj[key][index][index2] = {...item}
        // setApplication({...tempObj})
        // setSubmitting(false)
    }

    const handleLGDocs = (value) => {
        let tempObj = { ...application }

        tempObj.hasOwnHouse = value
        if (value === "No") {
            tempObj["locationGuarantorDocs"] = [...getDocumentMap()["LocationGuarantor"]]
        } else {
            tempObj["locationGuarantorDocs"] = []
        }

        setApplication({ ...tempObj })
    }

    const handleCurrentCountry = (value) => {
        let tempObj = { ...application }

        tempObj.currentlyInIndia = value
        if (value === "No") {
            tempObj["abroadDocs"] = [...getDocumentMap()["InAbroad"]]
        } else {
            tempObj["abroadDocs"] = []
        }
        setApplication({ ...tempObj })
    }

    const handleCoAppLocation = (value) => {
        let tempObj = { ...application }

        tempObj.coApplicantInIndia = value
        if (value === "No") {
            tempObj["abroadCoAppDocs"] = [...getDocumentMap()["AbroadCoApplicant"]]
        } else {
            tempObj["abroadCoAppDocs"] = []
        }

        setApplication({ ...tempObj })
    }

    const handleDocumentNameAdd = () => {
        if (docName.includes("/") || docName.includes("$") || docName.includes("[") || docName.includes("]") || docName.includes("#") || docName.includes(".")) {
            setSnackBar({ visibility: true, severity: "warning", message: "Sorry. File name can not include `/`, `.`, `[`, `]`, `#`, `$`" })
            return
        }
        let tempObj = { ...application }
        let addDocArray = tempObj.additionalDocuments ? tempObj.additionalDocuments : []
        let docObject = {
            name: docName,
            label: docName,
            url: ""
        }

        addDocArray.push(docObject)
        tempObj.additionalDocuments = [...addDocArray]
        setApplication({ ...tempObj })
        setDocName("")
    }

    const handleRemoveAdditionalDoc = (item, index) => {
        let tempObj = { ...application }
        let addDocArray = [...tempObj.additionalDocuments]
        addDocArray.splice(index, 1)
        tempObj.additionalDocuments = [...addDocArray]
        setApplication({ ...tempObj })
    }

    const handleOtherRelationAdd = () => {
        let tempObj = {...application}
        if (tempObj.additionalNonFinancialRelation && tempObj.additionalNonFinancialRelation.includes(otherRelationName)) {
            setSnackBar({ visibility: true, severity: "warning", message: "Sorry. You have already added this relation once." })
            return
        }

        let tempRelationArray = tempObj.additionalNonFinancialRelation?[...tempObj.additionalNonFinancialRelation]:[]
        tempRelationArray.push(otherRelationName)
        
        let tempDocs = tempObj.additionalNonFinancialDocs?[...tempObj.additionalNonFinancialDocs]:[]
        tempDocs.push(getDocumentMap()["NonFinancial"])
        
        tempObj.additionalNonFinancialDocs = [...tempDocs]
        tempObj.additionalNonFinancialRelation = [...tempRelationArray]

        setApplication({...tempObj})
    }

    const handleOtherRelationRemove = (item, index) => {
        let tempObj = { ...application }
        let addRelationArray = [...tempObj.additionalNonFinancialRelation]
        let addDocArray = [...tempObj.additionalNonFinancialDocs]
        addRelationArray.splice(index, 1)
        addDocArray.splice(index, 1)
        tempObj.additionalNonFinancialRelation = [...addRelationArray]
        tempObj.additionalNonFinancialDocs = [...addDocArray]
        setApplication({ ...tempObj })
    }

    const handleOutstandingCase = async () => {
        if (!application.outstandingReason) {
            setSnackBar({ visibility: true, severity: "warning", message: "Please enter the reason!" })
            return
        }
        setSubmitting(true)
        application.examsWritten = [...examsWritten]
        application.reLookIn = [...reLookIn]
        application.outstandingDate = moment().format("DD-MM-YYYY h:mm a")
        await set(ref(firebaseDatabase, `INCOGNITO_INTERESTED_LEADS/${userData.uid}/${application.phoneNumber}`), application)
        await set(ref(firebaseDatabase, `INCOGNITO_LOAN_APPLICATIONS/${application.phoneNumber}`), application)
        setSnackBar({ visibility: true, severity: "success", message: "Marked outstanding!" })
        setOutstandingModal(false)
    }

    return (
        <div className={Styles.appLeadDetailContainer}>
            <div className={Styles.headerContainer}>

                <div>
                    <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:15}}>
                        <h1 style={{ color: "#352F6C" }}>Lead Detail</h1>
                        <p style={{backgroundColor:"#c1ff8f", color:"#51b302", padding:"0 15px", borderRadius:5, fontWeight:600}}>{application.incognitoType}</p>
                    </div>
                    
                    {
                        application.specialNoteFromAdmin
                        &&
                        <p style={{backgroundColor:"yellow"}}>*Special note from Admin - <span style={{fontWeight:500}}>{application.specialNoteFromAdmin}</span></p>
                    }
                </div>

                <div className={Styles.qrMessageContainer}>
                    <p className={Styles.messageContainer}>Scan QR Code to make a call</p>
                    <div className={Styles.qrCodeContainer}>
                        <QRCode size={130} value={qrLink} viewBox={`0 0 256 256`} />
                        
                    </div>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, marginBottom: 40 }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                    <Tab label="Applicant Details" {...a11yProps(0)} />
                    <Tab label="More Details" {...a11yProps(1)} />
                    <Tab label="Upload App Docs" {...a11yProps(2)} />
                    <Tab label="Upload CS Docs" {...a11yProps(3)} />
                    <Tab label="Upload NF Docs" {...a11yProps(4)} />
                </Tabs>
            </div>

            {
                (application.subStatus.includes("Forwarded"))
                &&
                <div style={{ backgroundColor: "#98fab2", padding: 15, borderRadius: 10, marginTop: 20, marginBottom: 20 }}>
                    <p>This lead was forwarded to you by <span style={{ fontWeight: 600 }}>{application.forwardedBy.name}</span> due to language barrier. The student speaks <span style={{ fontWeight: 600 }}>{application.subStatus.split(" - ")[1]}</span>. Reach out to {application.forwardedBy.name} on <span style={{ fontWeight: 600 }}>{application.forwardedBy.phoneNumber}</span> for more details.</p>
                </div>
            }

            {
                (application.reAssignmentDetail && (application.reAssignmentDetail.reason === "Advior In-active" || application.reAssignmentDetail.reason === "Moved to TL"))
                &&
                <div style={{ backgroundColor: "#98fab2", padding: 15, borderRadius: 10, marginTop: 20, marginBottom: 20 }}>
                    <p>This lead was forwarded to you from <span style={{ fontWeight: 600 }}>{application.reAssignmentDetail.previousAdvisorName}</span>. The reason for reassignemnt is <span style={{ fontWeight: 600 }}>{application.reAssignmentDetail.reason}</span>.</p>
                </div>
            }

            <div className={Styles.formDocContainer}>
                {
                    value === 0
                    &&
                    <div className={Styles.formContainer}>
                        <InputContainer value={application.name} onChange={(event) => { setApplication({ ...application, name: event.target.value }) }} className={Styles.inputField} placeholder="Full Name" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={application.phoneNumber?"XXXXXXXX"+application.phoneNumber.substring(9, 12):""} readOnly className={Styles.inputField} placeholder="Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                        <InputContainer value={application.emailId} onChange={(event) => { setApplication({ ...application, emailId: event.target.value }) }} className={Styles.inputField} placeholder="Email Id" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Date of Birth</p>
                            <input type="date" value={application.dob} onChange={(event) => { setApplication({ ...application, dob: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Level of Education</p>
                            <Select style={{ width: "100%" }} value={application.levelOfStudy} onChange={(event) => { setApplication({ ...application, levelOfStudy: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="UG">UG</MenuItem>
                                <MenuItem value="PG">PG</MenuItem>
                                <MenuItem value="Diploma">Diploma</MenuItem>
                                <MenuItem value="Double Master">Double Master</MenuItem>
                                <MenuItem value="PHD">PHD</MenuItem>
                            </Select>
                        </div>

                        <div style={{ width: "100%", gridColumn: "1/2" }}>
                            <p className={Styles.label}>Currently in India?</p>
                            <Select style={{ width: "100%" }} value={application.currentlyInIndia} onChange={(event) => { handleCurrentCountry(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </Select>
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Abroad Phone Number</p>
                            <input value={application.abroadPhoneNumber} onChange={(event) => { setApplication({ ...application, abroadPhoneNumber: event.target.value }) }} className={Styles.inputField} placeholder="Abroad Phone Number" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>

                        <div style={{ width: "100%", gridColumn: "1/2" }}>
                            <p className={Styles.label}>Desired Country</p>
                            <input value={application.desiredCountry} onChange={(event) => { setApplication({ ...application, desiredCountry: event.target.value }) }} className={Styles.inputField} placeholder="Desired Country" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Desired University</p>
                            <input value={application.desiredUniversity} onChange={(event) => { setApplication({ ...application, desiredUniversity: event.target.value }) }} className={Styles.inputField} placeholder="Desired University" InputProps={{ disableUnderline: true }} variant="standard" />
                        </div>
                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Entrance Exams written</p>
                            <Select multiple style={{ width: "100%" }} value={examsWritten} onChange={(event) => { handleExams(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="GRE">GRE</MenuItem>
                                <MenuItem value="TOFEL">TOFEL</MenuItem>
                                <MenuItem value="IELTS">IELTS</MenuItem>
                                <MenuItem value="Duolingo">Duolingo</MenuItem>
                                <MenuItem value="PTE">PTE</MenuItem>
                                <MenuItem value="Other Exam">Other Exam</MenuItem>
                            </Select>
                        </div>

                        <InputContainer value={application.requiredLoanAmount.replace(/[^0-9]+/g, "")} onChange={(event) => { setApplication({ ...application, requiredLoanAmount: event.target.value }) }} className={Styles.inputField} placeholder="Loan amount required" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div className={Styles.yesNoContainer}>
                            <p>Have you applied to any Bank?</p>
                            <p style={{ color: application.hasPreAppliedToBank === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { setApplication({ ...application, hasPreAppliedToBank: "Yes" }) }}>Yes</p>
                            <p>|</p>
                            <p style={{ color: application.hasPreAppliedToBank === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { setApplication({ ...application, hasPreAppliedToBank: "No" }) }}>No</p>
                        </div>

                        <InputContainer value={application.preAppliedBank} readOnly={application.hasPreAppliedToBank !== "Yes"} onChange={(event) => { setApplication({ ...application, preAppliedBank: event.target.value }) }} className={Styles.inputField} placeholder="Bank Name" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Work experience?</p>
                            <Select style={{ width: "100%" }} value={application.hasWorkExperience} onChange={(event) => { handleWorkExperience(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="Yes">Currently Working</MenuItem>
                                <MenuItem value="Past">Used to work</MenuItem>
                                <MenuItem value="No">No work experience</MenuItem>
                            </Select>
                        </div>

                        <div className={Styles.yesNoContainer}>
                            <p>Have your own house?</p>
                            <p style={{ color: application.hasOwnHouse === "Yes" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { handleLGDocs("Yes") }}>Yes</p>
                            <p>|</p>
                            <p style={{ color: application.hasOwnHouse === "No" ? "rgba(53, 47, 108, 1)" : "rgba(53, 47, 108, 0.5)", cursor: "pointer" }} onClick={() => { handleLGDocs("No") }}>No</p>
                        </div>

                        <div style={{ width: "100%", gridColumn: "1/2" }}>
                            <p className={Styles.label}>Who will be your co-applicant?</p>
                            <Select style={{ width: "100%" }} value={application.coApplicant} onChange={(event) => { handleCoApplicantSelect(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                {
                                    Object.keys(relationMap).map((item, index) => {
                                        return (
                                            <MenuItem value={item}>{item}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                        {
                            application.coApplicant === "Other"
                            &&
                            <div style={{ width: "100%" }}>
                                <p className={Styles.label}>Co-applicant relation</p>
                                <input value={application.otherCoApplicant} onChange={(event) => { setApplication({ ...application, otherCoApplicant: event.target.value }) }} className={Styles.inputField} placeholder="Co-applicant relation" InputProps={{ disableUnderline: true }} variant="standard" />
                            </div>
                        }
                        {
                            application.coApplicant !== ""
                            &&
                            <>
                                <div style={{ width: "100%" }}>
                                    <p className={Styles.label}>Co-applicant's Profession?</p>
                                    <Select style={{ width: "100%" }} value={application.coApplicantProfession} onChange={(event) => { handleProfession(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                        <MenuItem value="Salaried">Salaried</MenuItem>
                                        <MenuItem value="Self Employed - Professional">Self Employed - Professional</MenuItem>
                                        <MenuItem value="Self Employed - Non Professional">Self Employed - Non Professional</MenuItem>
                                        <MenuItem value="Farmer">Farmer</MenuItem>
                                        <MenuItem value="Pensioner">Pensioner</MenuItem>
                                        <MenuItem value="Avanse Global">Avanse Global</MenuItem>
                                    </Select>
                                </div>
                                <div style={{ width: "100%" }}>
                                    <p className={Styles.label}>Co-applicant's Age?</p>
                                    <input value={application.coApplicantAge} onChange={(event) => { setApplication({ ...application, coApplicantAge: event.target.value }) }} className={Styles.inputField} placeholder="Co-applicant's Age" InputProps={{ disableUnderline: true }} variant="standard" />
                                </div>
                                <div style={{ width: "100%" }}>
                                    <p className={Styles.label}>Co-applicant in India?</p>
                                    <Select style={{ width: "100%" }} value={application.coApplicantInIndia} onChange={(event) => { handleCoAppLocation(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </div>
                            </>
                        }

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Is this a BT case?</p>
                            <Select style={{ width: "100%" }} value={application.isBTCase} onChange={(event) => { setApplication({ ...application, isBTCase: event.target.value }) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </Select>
                        </div>

                        <div style={{ width: "100%" }}>
                            <p className={Styles.label}>Re-look in?</p>
                            <Select multiple style={{ width: "100%" }} value={reLookIn} onChange={(event) => { setReLookIn(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                {
                                    getNbfcList().map((item) => {return (
                                        <MenuItem value={item.uid}>{item.name}</MenuItem>
                                    )})
                                }
                            </Select>
                        </div>
                    </div>
                }

                {
                    value === 1
                    &&
                    <div>
                        {
                            application.coApplicant === ""
                                ?
                                <p className={Styles.informationMessage}>Please select the co-applicant first!</p>
                                :
                                <div>
                                    {
                                        relationMap[application.coApplicant].map((item, index) => {
                                            return (
                                                <div className={Styles.nonFinListContainer}>
                                                    <div style={{ width: "100%" }}>
                                                        <p className={Styles.label} style={{ color: "rgba(0,0,0,0)" }}>Number</p>
                                                        <input value={"Non Financial Applicant " + (index + 1)} readOnly className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                                                    </div>

                                                    <div style={{ width: "100%" }}>
                                                        <p className={Styles.label}>Relation</p>
                                                        <input value={item} readOnly className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                                                    </div>

                                                    {
                                                        item === "Father" && (application.coApplicant !== "Father" && application.coApplicant !== "Mother")
                                                        &&
                                                        <div style={{ width: "100%" }}>
                                                            <p className={Styles.label}>Why isn't your {item} a financial Co-applicant?</p>
                                                            <Select style={{ width: "100%" }} value={application.whyFatherNotFin} onChange={(event) => { handleFatherReason(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                                                <MenuItem value="Poor Cibil">Poor Cibil</MenuItem>
                                                                <MenuItem value="No Income Proof or Low banking">No Income Proof or Low banking</MenuItem>
                                                                <MenuItem value="Divorced">Divorced</MenuItem>
                                                                <MenuItem value="Deceased">Deceased</MenuItem>
                                                            </Select>
                                                        </div>
                                                    }
                                                    {
                                                        item === "Mother" && (application.coApplicant !== "Father" && application.coApplicant !== "Mother")
                                                        &&
                                                        <div style={{ width: "100%" }}>
                                                            <p className={Styles.label}>Why isn't your {item} a financial Co-applicant?</p>
                                                            <Select style={{ width: "100%" }} value={application.whyMotherNotFin} onChange={(event) => { handleMotherReason(event.target.value) }} disableUnderline className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" >
                                                                <MenuItem value="Poor Cibil">Poor Cibil</MenuItem>
                                                                <MenuItem value="No Income Proof or Low banking">No Income Proof or Low banking</MenuItem>
                                                                <MenuItem value="Divorced">Divorced</MenuItem>
                                                                <MenuItem value="Deceased">Deceased</MenuItem>
                                                            </Select>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                        }
                    </div>
                }

                {
                    value === 2
                    &&
                    <>
                        <div className={Styles.documentListContainer}>
                            {
                                !application.applicantDocs && !application.examDocs && !application.currentlyInIndia
                                    ?
                                    <p className={Styles.informationMessage}>Complete information not available to generate LOD</p>
                                    :
                                    application.applicantDocs
                                        ?
                                        application.applicantDocs.map((item, index) => {
                                            return (
                                                <div className={Styles.documentItem}>
                                                    <p onClick={() => { handleDocumentUpload(item, "Applicant Docs", index, "applicantDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                                    <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                </div>
                                            )
                                        })
                                        :
                                        null
                            }

                        </div>
                        <div style={{ marginTop: 20 }} className={Styles.documentListContainer}>
                            {
                                application.abroadDocs
                                &&
                                application.abroadDocs.map((item, index) => {
                                    return (
                                        <div className={Styles.documentItem}>
                                            <p onClick={() => { handleDocumentUpload(item, "Applicant Docs", index, "abroadDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                            <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div style={{ marginTop: 20 }} className={Styles.documentListContainer}>
                            {
                                application.examDocs
                                &&
                                application.examDocs.map((item, index) => {
                                    return (
                                        <div className={Styles.documentItem}>
                                            <p onClick={() => { handleDocumentUpload(item, "Applicant Docs", index, "examDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                            <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
                }

                {
                    value === 3
                    &&
                    <div className={Styles.documentListContainer}>
                        {
                            application.coApplicantDocs
                                ?
                                application.coApplicantDocs.map((item, index) => {
                                    return (
                                        <div className={Styles.documentItem}>
                                            <p onClick={() => { handleDocumentUpload(item, "Co-Applicant Docs", index, "coApplicantDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                            <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                        </div>
                                    )
                                })
                                :
                                <p className={Styles.informationMessage}>Complete information not available to generate LOD</p>
                        }
                        {
                            application.abroadCoAppDocs
                            &&
                            application.abroadCoAppDocs.map((item, index) => {
                                return (
                                    <div className={Styles.documentItem}>
                                        <p onClick={() => { handleDocumentUpload(item, "Co-Applicant Docs", index, "abroadCoAppDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                        <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                    </div>
                                )
                            })
                        }
                    </div>
                }

                {
                    value === 4
                    &&
                    <div>
                        {
                            application.nonFinancialCoApplicants
                                ?
                                <>
                                    {
                                        application.nonFinancialCoApplicants.map((item, index) => {
                                            return (
                                                <div>
                                                    <p className={Styles.nonFinRelationLabel}>{item}</p>
                                                    <div className={Styles.documentListContainer}>
                                                        {
                                                            application.nonFinancialDocs[index].map((item2, index2) => {
                                                                return (
                                                                    <div className={Styles.documentItem}>
                                                                        <p onClick={() => { handleNestedDocumentUpload(item2, "Non Financial Applicant Docs", index, index2, "nonFinancialDocs") }} style={{ color: item2.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item2.name}</p>
                                                                        <FaExternalLinkSquareAlt onClick={() => { window.open(item2.url, "_blank") }} color={item2.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    <div>
                                        <p className={Styles.nonFinRelationClickableLabel} onClick={() => { setOtherRelationModal(true) }}>Additional Non-financial Applicant</p>
                                        {
                                            application.additionalNonFinancialRelation
                                            &&
                                            application.additionalNonFinancialRelation.map((item, index) => {
                                                return (
                                                    <div>
                                                        <div style={{display:"flex", flexDirection:"row", gap:10, alignItems:"center"}}>
                                                            <p className={Styles.nonFinRelationLabel}>{item}</p>
                                                            <AiFillCloseSquare color="red" size={20} style={{ cursor: "pointer", marginTop:35 }} onClick={() => { handleOtherRelationRemove(item, index) }} />
                                                        </div>
                                                        <div className={Styles.documentListContainer}>
                                                            {
                                                                application.additionalNonFinancialDocs[index].map((item2, index2) => {
                                                                    return (
                                                                        <div className={Styles.documentItem}>
                                                                            <p onClick={() => { handleNestedDocumentUpload(item2, "Additional Non Financial Applicant Docs", index, index2, "additionalNonFinancialDocs") }} style={{ color: item2.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item2.name}</p>
                                                                            <FaExternalLinkSquareAlt onClick={() => { window.open(item2.url, "_blank") }} color={item2.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    <div>
                                        <p className={Styles.nonFinRelationLabel}>Location Guarantor Documents</p>
                                        <div className={Styles.documentListContainer}>
                                            {
                                                application.locationGuarantorDocs
                                                    ?
                                                    application.locationGuarantorDocs.map((item, index) => {
                                                        return (
                                                            <div className={Styles.documentItem}>
                                                                <p onClick={() => { handleDocumentUpload(item, "Location Guarantor Docs", index, "locationGuarantorDocs") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                                                <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <p>None</p>
                                            }
                                        </div>
                                    </div>

                                    <div>
                                        <p className={Styles.nonFinRelationClickableLabel} onClick={() => { setDocNameModal(true) }}>Additional Documents</p>
                                        <div className={Styles.documentListContainer}>
                                            {
                                                application.additionalDocuments
                                                    ?
                                                    application.additionalDocuments.map((item, index) => {
                                                        return (
                                                            <div className={Styles.documentItem}>
                                                                <p onClick={() => { handleDocumentUpload(item, "Additional Docs", index, "additionalDocuments") }} style={{ color: item.url === "" ? null : "#12b004" }} className={Styles.documentName}>{item.name}</p>
                                                                <FaExternalLinkSquareAlt onClick={() => { window.open(item.url, "_blank") }} color={item.url === "" ? "" : "#12b004"} style={{ cursor: "pointer" }} />
                                                                <AiFillCloseSquare color="red" size={20} style={{ cursor: "pointer" }} onClick={() => { handleRemoveAdditionalDoc(item, index) }} />
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <p>None</p>
                                            }
                                        </div>
                                    </div>
                                </>
                                :
                                <p className={Styles.informationMessage}>Complete information not available to generate LOD</p>
                        }
                    </div>
                }
            </div>

            <div className={Styles.buttonsTypesContainer}>
                {
                    (application.outstandingDate && application.status !== "New")
                        ?
                        null
                        :
                        <>
                            {
                                application.status === "New" && !application.subStatus.includes("Yet to Call")
                                &&
                                <div className={Styles.buttonsContainer}>
                                    <Button onClick={() => { checkDialogContent() }} className={Styles.button} style={{ backgroundColor: submitting ? "#ccc" : "#352F6C" }} size='medium' variant='contained'>Interested</Button>
                                    <Button onClick={() => { setSelectedDisposition("Not Interested"); setMoreOptionsModal(true) }} className={Styles.button} style={{ backgroundColor: submitting ? "#ccc" : "#352F6C" }} size='medium' variant='contained'>Not Interested</Button>
                                    <Button onClick={() => { setDateTimeModal(true) }} className={Styles.button} style={{ backgroundColor: submitting ? "#ccc" : "#352F6C" }} size='medium' variant='contained'>Rescheduled</Button>
                                    <div className={Styles.verticalButtonsContainer}>
                                        <Button onClick={() => { setMoreOptionsVisible(!moreOptionsVisible) }} className={Styles.button} style={{ backgroundColor: submitting ? "#ccc" : "#352F6C" }} size='medium' variant='contained'>{moreOptionsVisible ? "Less Options" : "More Options"}</Button>
                                        {
                                            moreOptionsVisible
                                            &&
                                            <>
                                                <Button onClick={() => { handleChurning() }} variant="outlined" style={{ backgroundColor: "white", borderRadius: 0 }}>No response</Button>
                                                <Button onClick={() => { setNotDoableModal(true) }} variant="outlined" style={{ backgroundColor: "white", borderRadius: 0 }}>Not Doable</Button>
                                                <Button onClick={() => { moveToClosed("New", "Self Funded") }} variant="outlined" style={{ backgroundColor: "white", borderRadius: 0 }}>Self Funded</Button>
                                                <Button onClick={() => { moveToClosed("New", "DND") }} variant="outlined" style={{ backgroundColor: "white", borderRadius: 0 }}>DND</Button>
                                                <Button onClick={() => { setFutureIntakeModal(true) }} variant="outlined" style={{ backgroundColor: "white", borderRadius: 0 }}>Future Intake</Button>
                                                <Button onClick={() => { setSelectedDisposition("Already Applied"); setMoreOptionsModal(true) }} variant="outlined" style={{ backgroundColor: "white", borderRadius: 0 }}>Already Applied</Button>
                                                <Button onClick={() => { setLanguageModal(true) }} variant="outlined" style={{ backgroundColor: "white", borderRadius: 0 }}>Language Barrier</Button>
                                                <Button onClick={() => { moveToClosed("New", "Wrong/Invalid Number") }} variant="outlined" style={{ backgroundColor: "white", borderRadius: 0 }}>Wrong/Invalid Number</Button>
                                                <Button onClick={() => { setSelectedDisposition("Not Contactable"); setMoreOptionsModal(true) }} variant="outlined" style={{ backgroundColor: "white", borderRadius: 0 }}>Not Contactable</Button>
                                            </>
                                        }
                                    </div>
                                </div>
                            }

                            {
                                application.status === "Interested"
                                &&
                                <div className={Styles.interestedButtonContainer}>
                                    <div className={Styles.specialCaseContainer}>
                                        <FormControlLabel control={<Checkbox checked={application.isSpecialCase} onChange={(event) => { setApplication({ ...application, isSpecialCase: event.target.checked }) }} />} label="Tick this if the Applicant is a Special Case." />
                                        <InputContainer value={application.specialCaseDescription} onChange={(event) => { setApplication({ ...application, specialCaseDescription: event.target.value }) }} placeholder="Please write a small description" className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                                    </div>
                                    <div className={Styles.interestedTwoButtonContainer}>
                                        <Button onClick={() => { setOutstandingModal(true) }} style={{ backgroundColor: submitting ? "#ccc" : "#352F6C", width: 200, height: 40, marginTop: "auto" }} size='medium' variant='contained'>Mark Outstanding</Button>
                                        <Button onClick={() => { handleSave() }} style={{ backgroundColor: submitting ? "#ccc" : "#352F6C", width: 200, height: 40, marginTop: "auto" }} size='medium' variant='contained'>Save</Button>
                                    </div>
                                </div>
                            }

                            {
                                application.status === "Case Details" && application.isLodVerified === "No"
                                &&
                                <div className={Styles.cdButtonsContainer}>
                                    <Button onClick={() => { handleLodSave() }} style={{ backgroundColor: submitting ? "#ccc" : "#352F6C", width: 200, height: 40, marginTop: "auto" }} size='medium' variant='contained'>Save</Button>
                                    <Button onClick={() => { handleVerifyLod() }} style={{ backgroundColor: submitting ? "#ccc" : "#352F6C", width: 200, height: 40, marginTop: "auto" }} size='medium' variant='contained'>Mark LOD Verified</Button>
                                </div>
                            }
                        </>
                }
            </div>

            {/* Language Modal */}
            <Modal open={languageModal} onClose={() => { setLanguageModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p>Please select customer's preferred language</p>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                            {
                                languageList.map((item, index) => {
                                    return (
                                        <div key={JSON.stringify(item)} className={Styles.itemContainer}>
                                            <p className={Styles.productName}>{item}</p>
                                            <Switch value={language === item} checked={language === item} onChange={(event) => { if (event.target.checked) { setLanguage(item) } }} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className={Styles.modalButtonContainer}>
                            <Button disabled={submitting} sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setLanguageModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button disabled={submitting} onClick={() => { handleLanguageSelect() }} variant='contained' className={Styles.primaryButton}>{submitting ? "Forwarding" : "Proceed"}</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Account Modal */}
            <Modal open={accountModal} onClose={() => { setAccountModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p>Are you sure you want to mark this lead <span style={{ fontWeight: 700 }}>Interested</span>?</p>
                        <p style={{ marginTop: 15 }}>An account will automatically be created for <span style={{ fontWeight: 600 }}>{application.name}</span> linked to Email ID - <span style={{ fontWeight: 600 }}>{application.emailId}</span>.</p>

                        <div className={Styles.modalButtonContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setAccountModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { moveToInterested() }} variant='contained' className={Styles.primaryButton}>Proceed</Button>
                        </div>
                    </div>


                </Box>
            </Modal>

            {/* Date Time Modal */}
            <Modal open={dateTimeModal} onClose={() => { setDateTimeModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p style={{ marginBottom: 20 }}>Please select the date and time for the next call.</p>

                        <input type="date" value={rescheduleDateTime.date} onChange={(event) => { setRescheduleDateTime({ ...rescheduleDateTime, date: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />
                        <input style={{ marginTop: 10 }} type="time" value={rescheduleDateTime.time} onChange={(event) => { setRescheduleDateTime({ ...rescheduleDateTime, time: event.target.value }) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />

                        <div className={Styles.modalButtonContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setDateTimeModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { moveToReminder() }} variant='contained' className={Styles.primaryButton}>Reschedule</Button>
                        </div>
                    </div>


                </Box>
            </Modal>

            {/* Additional Document Modal */}
            <Modal open={docNameModal} onClose={() => { setDocNameModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle} style={{ fontWeight: 400, marginBottom: 10 }}>Please enter the Document Name below</p>
                        <input value={docName} onChange={(event) => { setDocName(event.target.value) }} className={Styles.inputField} placeholder="Document Name" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div className={Styles.buttonsContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setDocNameModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { handleDocumentNameAdd() }} variant='contained' className={Styles.primaryButton}>Add</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Additional Non-financial Modal */}
            <Modal open={otherRelationModal} onClose={() => { setOtherRelationModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle} style={{ fontWeight: 400, marginBottom: 10 }}>Please enter the Relation below</p>
                        <input value={otherRelationName} onChange={(event) => { setOtherRelationName(event.target.value) }} className={Styles.inputField} placeholder="Relation" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div className={Styles.buttonsContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setOtherRelationModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { handleOtherRelationAdd() }} variant='contained' className={Styles.primaryButton}>Add</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Outstanding Modal */}
            <Modal open={outstandingModal} onClose={() => { setOutstandingModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle} style={{ fontWeight: 400, marginBottom: 10 }}>Please enter the reason</p>
                        <input value={application.outstandingReason} onChange={(event) => { setApplication({ ...application, outstandingReason: event.target.value }) }} className={Styles.inputField} placeholder="" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div className={Styles.buttonsContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setOutstandingModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { handleOutstandingCase() }} variant='contained' className={Styles.primaryButton}>Add</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* More Options Modal */}
            <Modal open={moreOptionsModal} onClose={() => { setMoreOptionsModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle} style={{ fontWeight: 400, marginBottom: 10 }}>Please specify</p>
                        
                        {
                            selectedDisposition === "Not Interested"
                            &&
                            <Select value={moreOptionsReason} onChange={(event) => { setMoreOptionsReason(event.target.value) }} className={Styles.inputField} placeholder="" InputProps={{ disableUnderline: true }} variant="standard">
                                <MenuItem value={"No travel plans"}>No travel plans</MenuItem>
                                <MenuItem value={"Visa rejected"}>Visa rejected</MenuItem>
                                <MenuItem value={"Working"}>Working</MenuItem>
                            </Select>
                        }
                        {
                            selectedDisposition === "Already Applied"
                            &&
                            <Select value={moreOptionsReason} onChange={(event) => { setMoreOptionsReason(event.target.value) }} className={Styles.inputField} placeholder="" InputProps={{ disableUnderline: true }} variant="standard">
                                <MenuItem value="Sanctioned">Sanctioned</MenuItem>
                                <MenuItem value="Disbursed less than 1 month ago">Disbursed less than 1 month ago</MenuItem>
                                <MenuItem value="Disbursed 1-3 months ago">Disbursed 1-3 months ago</MenuItem>
                                <MenuItem value="Disbursed 4-6 months ago">Disbursed 4-6 months ago</MenuItem>
                                <MenuItem value="Disbursed more than 6 months ago">Disbursed more than 6 months ago</MenuItem>
                                <MenuItem value="Rejected">Rejected</MenuItem>
                            </Select>
                        }

                        {
                            selectedDisposition === "Not Contactable"
                            &&
                            <Select value={moreOptionsReason} onChange={(event) => { setMoreOptionsReason(event.target.value) }} className={Styles.inputField} placeholder="" InputProps={{ disableUnderline: true }} variant="standard">
                                <MenuItem value="No incoming">No incoming</MenuItem>
                                <MenuItem value="Out of service">Out of service</MenuItem>
                                <MenuItem value="Switch off">Switch off</MenuItem>
                                <MenuItem value="Service suspended">Service suspended</MenuItem>
                            </Select>
                        }

                        <div className={Styles.buttonsContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setMoreOptionsModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { moveToClosed("New", selectedDisposition+" - "+moreOptionsReason) }} variant='contained' className={Styles.primaryButton}>Submit</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Not doable Modal */}
            <Modal open={notDoableModal} onClose={() => { setNotDoableModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle} style={{ fontWeight: 400, marginBottom: 10 }}>Please enter the reason</p>
                        <input value={application.notDoableReason} onChange={(event) => { setApplication({ ...application, notDoableReason: event.target.value }) }} className={Styles.inputField} placeholder="" InputProps={{ disableUnderline: true }} variant="standard" />

                        <div className={Styles.buttonsContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setNotDoableModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { moveToClosed("New", "Not Doable") }} variant='contained' className={Styles.primaryButton}>Submit</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            {/* Future Intake Modal */}
            <Modal open={futureIntakeModal} onClose={() => { setFutureIntakeModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}>
                        <p className={Styles.modalTitle} style={{ fontWeight: 400, marginBottom: 10 }}>Please specify semester</p>
                        
                        <Select value={futureSemester} onChange={(event) => {setFutureSemester(event.target.value)}} className={Styles.inputField} placeholder="" InputProps={{ disableUnderline: true }} variant="standard">
                            <MenuItem value={"Fall"}>Fall</MenuItem>
                            <MenuItem value={"Summer"}>Summer</MenuItem>
                            <MenuItem value={"Spring"}>Spring</MenuItem>
                        </Select>

                        <p className={Styles.modalTitle} style={{ fontWeight: 400, marginBottom: 10 }}>Please specify year</p>

                        <Select value={futureYear} onChange={(event) => {setFutureYear(event.target.value)}} className={Styles.inputField} placeholder="" InputProps={{ disableUnderline: true }} variant="standard">
                            {
                                ["2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033", "2034", "2035"].map((item, index) => {return (
                                    <MenuItem value={item}>{item}</MenuItem>
                                )})
                            }
                        </Select>

                        <div className={Styles.buttonsContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setFutureIntakeModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { moveToFutureIntake("Planning for "+ futureSemester + "'"+futureYear.substring(2, 4)) }} variant='contained' className={Styles.primaryButton}>Submit</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Snackbar open={snackBar.visibility} autoHideDuration={3000} onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }}>
                <Alert onClose={() => { setSnackBar({ visibility: false, message: "", severity: "" }) }} severity={snackBar.severity} variant="filled" sx={{ width: '100%' }}>{snackBar.message}</Alert>
            </Snackbar>
        </div>
    )
}

export default AdvisorLeadDetailIncognito