import React, { useState } from "react";
import Styles from "./ResourcesTab.module.css"
import { Box, Button, MenuItem, Modal, Select } from "@mui/material";
import CustomAutoComplete from "../../CustomAutoComplete/CustomAutoComplete.component";
import { onValue, ref } from "firebase/database";
import { firebaseDatabaseAdmin } from "../../../backend/firebaseHandler";
import ProdigyUniversityList from "../../../workload/ProdigyUniversityList";

const ResourcesTab = () => {

    const [pincodeModal, setPincodeModal] = useState(false)
    const [pincode, setPincode] = useState("")
    const [selectedPincodeBank, setSelectedPincodeBank] = useState("")

    const [ubListModal, setUbListModal] = useState(false)
    const [selectedListBank, setSelectedListBank] = useState("")
    const [searchWord, setSearchWord] = useState("")
    const [matchingResult, setMatchingResult] = useState([])
    const [prodigyModal, setProdigyModal] = useState(false)
    const [prodigyUniversity, setProdigyUniversity] = useState("")
    const [prodigyResultObject, setProdigyResultObject] = useState({})
    const [prodigySchool, setProdigySchool] = useState("")

    const handleCheckPincode = () => {

        let dbRef = null
        console.log(selectedPincodeBank, pincode)
        if (selectedPincodeBank === "Credila") {
            dbRef = ref(firebaseDatabaseAdmin, `CREDILA_PINCODES/${pincode}`)
        } else if (selectedPincodeBank === "IDFC") {
            dbRef = ref(firebaseDatabaseAdmin, `IDFC_PINCODES/${pincode}`)
        }

        onValue(dbRef, (snap) => {
            console.log("here")
            if (snap.exists()) {
                alert("Area functional.")
            } else {
                alert("Area not functional.")
            }
        }, { onlyOnce: true })
    }

    const handleCheckUniversity = () => {
        let arr = []

        let dbRef = null

        if (selectedListBank === "Union Bank") {
            dbRef = ref(firebaseDatabaseAdmin, `UNION_BANK_LIST`)
        } else if (selectedListBank === "Avanse (STEM)") {
            dbRef = ref(firebaseDatabaseAdmin, `AVANSE_STEM_LIST`)
        } else if (selectedListBank === "Avanse (MBA)") {
            dbRef = ref(firebaseDatabaseAdmin, `AVANSE_MBA_LIST`)
        } else if (selectedListBank === "Axis MS (STEM)") {
            dbRef = ref(firebaseDatabaseAdmin, `AXIS_MS_STEM`)
        } else if (selectedListBank === "Axis Other Master (STEM)") {
            dbRef = ref(firebaseDatabaseAdmin, `AXIS_OTHER_MASTER_STEM`)
        } else if (selectedListBank === "Yes Bank") {
            dbRef = ref(firebaseDatabaseAdmin, `YES_BANK`)
        } else if (selectedListBank === "ICICI") {
            dbRef = ref(firebaseDatabaseAdmin, `ICICI_LIST`)
        }

        onValue(dbRef, (snap) => {
            if (snap.exists()) {
                for (const key in snap.val()) {
                    if (snap.child(key).val().toLowerCase().includes(searchWord.toLowerCase())) {
                        console.log("here")
                        arr.push(snap.child(key).val())
                    }
                }
                setMatchingResult(arr)
            }
        }, { onlyOnce: true })
    }

    const handleProdigyUniversity = (value) => {
        setProdigySchool("")
        setProdigyUniversity(value)
        onValue(ref(firebaseDatabaseAdmin, `PRODIGY_SUPPORTED_UNIVERSITIES/${value}`), (snap) => {
            if (snap.exists()) {
                setProdigyResultObject(snap.val())
            } else {
                alert("Something went wrong")
            }
        }, {onlyOnce:true})
    }

    return (
        <div className={Styles.resourcesTabContainer}>
            <div className={Styles.headerContainer}>
                <h1 style={{ color: "#352F6C" }}>Resources</h1>
            </div>

            <div style={{marginTop:40}}>
                <p onClick={() => { setPincodeModal(true) }} style={{ color: "#352F6C", fontWeight: 600, cursor: "pointer", textDecoration: "underline" }}>Check Approved Pincode</p>
                <p onClick={() => { setUbListModal(true) }} style={{ color: "#352F6C", fontWeight: 600, cursor: "pointer", textDecoration: "underline" }}>Check Approved University</p>
                <p onClick={() => { setProdigyModal(true) }} style={{ color: "#352F6C", fontWeight: 600, cursor: "pointer", textDecoration: "underline" }}>Check Prodigy Courses</p>
            </div>

            <Modal open={pincodeModal} onClose={() => { setPincodeModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer}  style={{ alignItems: "flex-start" }}>
                        <p>Select Bank</p>
                        <Select value={selectedPincodeBank} style={{ width: 200, textAlign:"start" }} onChange={(event) => { setSelectedPincodeBank(event.target.value) }}>
                            {
                                ["IDFC"].map((item2, index2) => {
                                    return (
                                        <MenuItem key={index2} value={item2}>{item2}</MenuItem>
                                    )
                                })
                            }
                        </Select>

                        <p className={Styles.modalTitle} style={{ fontWeight: 400, marginBottom: 10, marginTop:30 }}>Please enter the student's pincode below</p>
                        <input value={pincode} onChange={(event) => { setPincode(event.target.value) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />

                        <div className={Styles.buttonsContainer}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setPincodeModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { handleCheckPincode() }} variant='contained' className={Styles.primaryButton}>Check</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal open={ubListModal} onClose={() => { setUbListModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer} style={{ alignItems: "flex-start" }}>

                        <p>Select Bank</p>
                        <Select value={selectedListBank} style={{ width: 200, textAlign:"start" }} onChange={(event) => { setSelectedListBank(event.target.value) }}>
                            {
                                ["Union Bank", "Avanse (STEM)", "Avanse (MBA)", "Axis MS (STEM)", "Axis Other Master (STEM)", "Yes Bank", "ICICI"].map((item2, index2) => {
                                    return (
                                        <MenuItem key={index2} value={item2}>{item2}</MenuItem>
                                    )
                                })
                            }
                        </Select>

                        <p className={Styles.modalTitle} style={{ fontWeight: 400, marginBottom: 10, marginTop: 30 }}>Please enter the student's university below</p>
                        <input readOnly={selectedListBank === ""} value={searchWord} onChange={(event) => { setSearchWord(event.target.value) }} className={Styles.inputField} InputProps={{ disableUnderline: true }} variant="standard" />


                        <p style={{ fontWeight: 400, marginBottom: 10, marginTop: 30, color: "green" }}>Matching Result</p>
                        {
                            matchingResult.length === 0
                            ?
                            <p style={{color:"#ccc", fontStyle:"italic", fontWeight:500}}>No matching universities</p>
                            :
                            <>
                                <div style={{ display: "flex", flexDirection: 'row', gap: 10, maxWidth: 600, flexWrap: "wrap" }}>
                                    {
                                        matchingResult.slice(0, 5).map((item) => {
                                            return (
                                                <p style={{ backgroundColor: "#a6f7aa", padding: "2px 10px 2px 10px", borderRadius: 10, fontWeight: 400, color: "#02b50b" }}>{item}</p>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    matchingResult.length > 5
                                    &&
                                    <p style={{ fontWeight: 400, marginBottom: 10, marginTop: 10, color: "green" }}>...and {matchingResult.length - 5} more!</p>
                                }
                            </>
                        }


                        <div className={Styles.buttonsContainer} style={{ width: "100%", justifyContent: "center" }}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => { setUbListModal(false) }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            <Button onClick={() => { handleCheckUniversity() }} variant='contained' className={Styles.primaryButton}>Check</Button>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal open={prodigyModal} onClose={() => { setProdigyModal(false) }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div className={Styles.modalContainer} style={{ alignItems: "flex-start", justifyContent:"flex-start" }}>

                        <p>Select University</p>
                        <CustomAutoComplete
                            className={Styles.inputField}
                            label="Select University"
                            variant="standard"
                            value={prodigyUniversity}
                            InputProps={{ disableUnderline: true }}
                            onChange={(event, value) => {handleProdigyUniversity(value)}}
                            options={ProdigyUniversityList}
                        />  

                        {
                            prodigyUniversity !== ""
                            &&
                            <>
                                <p>Select School</p>
                                <Select value={prodigySchool} onChange={(event)=>{setProdigySchool(event.target.value)}} style={{width:'100%'}}>
                                    {
                                        Object.keys(prodigyResultObject).map((item, index) => {
                                            return(
                                                <MenuItem value={item}>{item}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </>
                        }
                        

                        {
                            prodigySchool !== ""
                            &&
                            <>
                                <p>Courses Approved</p>
                                <Select style={{width:'100%'}}>
                                    {
                                        prodigyResultObject[prodigySchool]
                                        &&
                                        prodigyResultObject[prodigySchool].map((item, index) => {
                                            return(
                                                <MenuItem>{item}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </>
                        }

                        

                        <div className={Styles.buttonsContainer} style={{ width: "100%", justifyContent: "center" }}>
                            <Button sx={{ backgroundColor: "rgba(53, 47, 108, 0.5)" }} onClick={() => {setProdigyModal(false); setProdigySchool(""); setProdigyUniversity("") }} variant='contained' className={Styles.secondaryButton}>Cancel</Button>
                            {/* <Button onClick={() => { handleProdigyUniversity() }} variant='contained' className={Styles.primaryButton}>Fetch Courses</Button> */}
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default ResourcesTab